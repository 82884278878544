import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../auth.service";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  hide = true;
  isLoading = false;
  isLogin = false;
  date: string;
  hour: number;
  minute: number;
  second: number;
  year: number;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    const timeStamp = new Date();
    this.year = timeStamp.getFullYear();
    setInterval(() => {
      const currentDate = new Date();
      this.hour = currentDate.getHours();
      this.minute = currentDate.getMinutes();
      this.second = currentDate.getSeconds();
    }, 1000);
  }

  onLogin(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.isLogin = true;
    this.authService.login(form.value.email, form.value.password);
  }
}
