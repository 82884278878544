import {NgModule} from '@angular/core';
import {InstallmentRoutingModule} from './installment-routing.module';
import {InstallmentListComponent} from './installment-apps/installment-list/installment-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {InsCustomersComponent} from './installment-apps/customers/ins-customers.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {InsCustomerAddComponent} from './installment-apps/customers/ins-cutomer-add/ins-customer-add.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AgGridModule} from 'ag-grid-angular';
import {InsWindowComponent} from './installment-apps/installment-sale/installment-window/ins-window.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {InsSaleCheckComponent} from './installment-apps/installment-sale/check/check-sale/ins-sale-check.component';
import {PrintLayoutComponent} from './installment-apps/installment-sale/check/print-layout/print-layout.component';
import {InsSaleListComponent} from './installment-apps/sale-list/ins-sale-list/ins-sale-list.component';
import {InsOperListComponent} from './installment-apps/ins-operations/ins-operation-list/ins-oper-list.component';
import {InsOperAddComponent} from './installment-apps/ins-operations/ins-oper-add/ins-oper-add.component';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxMaskModule} from 'ngx-mask';
import { ContractFormComponent } from './installment-apps/installment-sale/contract-form/contract-form.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    InstallmentListComponent,
    InsCustomersComponent,
    InsCustomerAddComponent,
    InsWindowComponent,
    InsSaleCheckComponent,
    PrintLayoutComponent,
    InsSaleListComponent,
    InsOperListComponent,
    InsOperAddComponent,
    ContractFormComponent
  ],
  imports: [
    InstallmentRoutingModule,
    TranslateModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    CommonModule,
    ReactiveFormsModule,
    AgGridModule,
    MatAutocompleteModule,
    MatSelectModule,
    FormsModule,
    MatTableModule,
    MatDialogModule,
    MatListModule,
    CurrencyMaskModule,
    NgxQRCodeModule,
    MatTabsModule,
    NgxMaskModule,
    MatProgressSpinnerModule,
  ],
  exports: []
})

export class InstallmentModule {}
