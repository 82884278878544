import { NgModule } from "@angular/core";
import { ProductionComponent } from "./production-list/production.component";
import { ProdRoutingModule } from "./prod-routing.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { CommonModule } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
import { AgGridModule } from "ag-grid-angular";
import { ConstructorComponent } from "./production-list/constructor/constructor.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MProductionComponent } from "./manual-production/m-production.component";
import { MatBadgeModule } from "@angular/material/badge";
import { ProductionMListComponent } from "./manual-production/production-m-list/production-m-list.component";
import { PMListItemsComponent } from "./manual-production/production-m-list/p-m-list-items/p-m-list-items.component";
import { ProductionStatComponent } from "./production-main/production-stat.component";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { ChartsModule } from "ng2-charts";
import { CrmModule } from "../crm/crm.module";
import { NgxMaskModule } from "ngx-mask";

@NgModule({
  declarations: [
    ProductionComponent,
    ConstructorComponent,
    MProductionComponent,
    ProductionMListComponent,
    PMListItemsComponent,
    ProductionStatComponent,
  ],
  exports: [],
  imports: [
    ProdRoutingModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    AgGridModule,
    TranslateModule,
    MatTooltipModule,
    MatBadgeModule,
    MatCardModule,
    MatGridListModule,
    ChartsModule,
    CrmModule,
    NgxMaskModule,
  ],
})
export class ProdModule {}
