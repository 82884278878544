import { Component, OnInit } from "@angular/core";
import { AllModules, Module } from "@ag-grid-enterprise/all-modules";
import { AccountingService } from "../accounting.service";
import { MatDialog } from "@angular/material/dialog";
import { ExchangeCurrencyComponent } from "../exchange-currency/exchange-currency.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CashierService } from "../../cashier/cashier.service";
import { GridApi, ColumnApi } from "ag-grid-community";

interface AdminBalance {
  id: number;
  cost_sum: number;
  cost_dollar: number;
  cost_hr: number;
  cost_v_hr: number;
}

interface CloseDay {
  id: number;
  cr_on: string;
  in_sum: number;
  in_dollar: number;
  in_hr: number;
}

@Component({
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["admin-dashboard.component.css"],
})
export class AdminDashboardComponent implements OnInit {
  constructor(
    private accountingService: AccountingService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private cashierService: CashierService
  ) {
    this.defaultColDef = { resizeable: true };
  }

  rowData: any;
  rowData1: any;
  rowDataBalance: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  private columnApi: ColumnApi;
  adminExchange: FormGroup;
  sumCost = 0;
  dollarCost = 0;
  hrCost = 0;
  vhrCost = 0;
  totalcustomer = 0;
  inventoryTotal = 0;
  closeDay$: CloseDay[] = [];
  balance: any;
  isAdmin = false;
  isLoading = false;

  columnDefs = [
    { headerName: "#", field: "id", sortable: true, width: 70 },
    {
      headerName: "Sana",
      field: "cr_on",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 120,
    },
    {
      headerName: "Turi",
      field: "type",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Summa",
      field: "cost_sum",
      sortable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Kurs",
      field: "rate",
      sortable: true,
      width: 150,
      cellRenderer: this.currencyCellRendererSum1,
    },
    {
      headerName: "Natija",
      field: "result_cost",
      sortable: true,
      width: 150,
      cellRenderer: this.currencyCellRendererSum,
    },
  ];

  tColumnDefs = [
    { headerName: "#", field: "id", sortable: true, width: 100 },
    {
      headerName: "Sana",
      field: "cr_on",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 100,
    },
    {
      headerName: "Filial",
      field: "branch",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      headerName: "Sum",
      field: "in_sum",
      sortable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Dollar",
      field: "in_dollar",
      sortable: true,
      width: 150,
      cellRenderer: this.currencyCellRendererSum,
    },
    {
      headerName: "Hr",
      field: "in_hr",
      sortable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Chiqim sum",
      field: "out_sum",
      sortable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Chiqim dollar",
      field: "out_dollar",
      sortable: true,
      width: 150,
      cellRenderer: this.currencyCellRendererSum,
    },
    {
      headerName: "Chiqim hr",
      field: "out_hr",
      sortable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
  ];

  ColumnDefsBalance = [
    { headerName: "#", field: "id", sortable: true, width: 100 },
    {
      headerName: "Nomi",
      field: "name",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 300,
    },
    {
      headerName: "UZS (Naqt)",
      field: "sum",
      sortable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
      cellStyle: this.cellStyling,
    },
    {
      headerName: "USD (Naqt)",
      field: "dollar",
      sortable: true,
      width: 150,
      cellRenderer: this.currencyCellRendererSum,
      cellStyle: this.cellStylinghrDollar,
    },
    {
      headerName: "(UZS) HR",
      field: "hr",
      sortable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
      cellStyle: this.cellStylingHr,
    },
    {
      headerName: "(USD) hr",
      field: "dollar_hr",
      sortable: true,
      width: 150,
      cellRenderer: this.currencyCellRendererSum,
      cellStyle: this.cellStylinghrDollarHr,
    },
  ];

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public barChartLabels = [];
  public barChartType = "line";
  public barChartLegend = true;
  data1 = [];
  data2 = [];
  data3 = [];
  public barChartData = [
    { data: this.data1, label: "Kunlik savdo UZS" },
    { data: this.data2, label: "Kunlik savdo USD" },
    { data: this.data3, label: "Kunlik savdo HR" },
  ];

  public barChartOptions1 = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public barChartLabels1 = ["UZS", "USD", "HR", "VHR"];
  public barChartType1 = "bar";
  public barChartLegend1 = true;
  data4 = [];
  public barChartData1 = [
    // {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'},
    { data: this.data4, label: "Umumiy balans" },
  ];

  // Currency renderer functions
  currencyFormatter(params) {
    // return '' + this.formatNumber(params.value);
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  private currencyCellRendererSum(params: any) {
    const usdFormate = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
    });
    return usdFormate.format(params.value);
  }

  private currencyCellRendererSum1(params: any) {
    const usdFormate = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
    });
    return usdFormate.format(params.value);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.accountingService.getAccountingPermissions().subscribe((result) => {
      if (result.appList[0] !== undefined) {
        if (result.appList[0].id === 1) {
          this.isAdmin = true;
        }
      }
    });
    this.adminExchange = this.fb.group({
      type: ["", Validators.required],
      cost: ["", [Validators.required, Validators.maxLength(15)]],
      rate: ["", [Validators.required, Validators.maxLength(8)]],
    });
    this.onRenderComponent();
    this.accountingService
      .getDailySaleChart()
      .subscribe((result: CloseDay[]) => {
        this.isLoading = false;
        this.closeDay$ = result;
        result.forEach((x) => {
          this.barChartLabels.push(x.cr_on);
          this.data1.push(x.in_sum);
          this.data2.push(x.in_dollar);
          this.data3.push(x.in_hr);
        });
      });
    this.accountingService.getBalanceTotal().subscribe((result) => {
      this.totalcustomer = result.crm_get_total_balance;
    });

    this.accountingService.getInventoryTotal().subscribe((result) => {
      this.inventoryTotal = result[0].total_cost;
    });
  }

  onRenderComponent() {
    this.rowData1 = this.accountingService.getCloseDayTransactions();
    this.rowData = this.accountingService.getExchangeCurrency();
    this.rowDataBalance = this.cashierService.getBalance();

    this.accountingService
      .getAdminBalance()
      .subscribe((result: AdminBalance[]) => {
        this.sumCost = result[0].cost_sum / 100;
        this.dollarCost = result[0].cost_dollar / 100;
        this.hrCost = result[0].cost_hr / 100;
        this.vhrCost = result[0].cost_v_hr / 100;

        this.data4.push(result[0].cost_sum / 100);
        this.data4.push(result[0].cost_dollar / 100);
        this.data4.push(result[0].cost_hr / 100);
        this.data4.push(result[0].cost_v_hr / 100);
      });
  }

  onGridReady({ api }: { api: GridApi }) {
    this.gridApi = api;
    api.sizeColumnsToFit();
  }

  onExchange() {
    const dialogRef = this.dialog.open(ExchangeCurrencyComponent, {
      panelClass: ["animate__animated", "animate__slideInRight"],
      width: "30%",
      height: "100%",
      position: { right: "0" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.onRenderComponent();
    });
  }

  onClicked() {
    this.router.navigate(["../../accounting/operation"]);
  }

  cellStyling(params: any) {
    if (params.data.sum < 0) {
      return { backgroundColor: "#f08a90", color: "white" };
    } else if (params.data.sum > 0) {
      return { backgroundColor: "#28a745", color: "white" };
    }
  }

  cellStylingHr(params: any) {
    if (params.data.hr < 0) {
      return { backgroundColor: "#f08a90", color: "white" };
    } else if (params.data.hr > 0) {
      return { backgroundColor: "#28a745", color: "white" };
    }
  }

  cellStylinghrDollar(params: any) {
    if (params.data.dollar < 0) {
      return { backgroundColor: "#f08a90", color: "white" };
    } else if (params.data.dollar > 0) {
      return { backgroundColor: "#28a745", color: "white" };
    }
  }

  cellStylinghrDollarHr(params: any) {
    if (params.data.dollar_hr < 0) {
      return { backgroundColor: "#f08a90", color: "white" };
    } else if (params.data.dollar_hr > 0) {
      return { backgroundColor: "#28a745", color: "white" };
    }
  }
}
