import {Component, Inject, OnInit, Optional} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {InventoryService} from '../../../inventory.service';
import {Router} from "@angular/router";
import {take} from "rxjs/operators";

@Component({
  templateUrl: './invoice-p-list.component.html',
  styleUrls: ['./invoice-p-list.component.css']
})
export class InvoicePListComponent implements OnInit {
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private inventoryService: InventoryService,
    private router: Router
  ) {
    this.defaultColDef = { resizeable: true };
  }

  columnDefs = [
    {headerName: 'Barcode', field: 'barcode', sortable: true, checkboxSelection: true, width: 100},
    {headerName: 'Nomi', field: 'name', sortable: true, width: 200},
    {headerName: 'Turi', field: 'type', sortable: true, width: 100},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: 'Narxi', field: 'cost', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: 'Summa', field: 'sum', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: '%', field: 'bt', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
    {headerName: '% summa', field: 'sum_bt', sortable: true, width: 100, cellRenderer: this.CurrencyCellRendererDollar},
  ];

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts(): void {
    this.inventoryService.getInvoiceProductsName(this.data.id, this.data.operation_type_id).pipe(take(1))
      .subscribe(r => {
        this.rowData = r;
      });
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  private CurrencyCellRendererDollar(params: any) {
    const usdFormate = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0
    });
    return usdFormate.format(params.value);
  }

  onReturn() {
    if (confirm('Rostdan qaytarmoqchimisiz?')) {
      if (this.data !== null) {
        this.inventoryService.posReturnSale(this.data.id)
          .subscribe( result => {
            if (result.message === '1') {
              this.router.navigate(['inventory/invoice/add'], {state: this.data});
            }
          });
      }
    }
  }
}
