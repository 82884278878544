import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ProdService} from '../prod.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {CurrencyCellRendererQuantity} from '../../test/table-currency';
import {dateParser} from '../../crm/dateParser';
import {Line} from '../manual-production/m-production.component';

@Component({
  selector: 'app-production-stat',
  templateUrl: './production-stat.component.html',
  styleUrls: ['./production-stat.component.css']
})
export class ProductionStatComponent implements OnInit {

  isLoading = false;
  rowData: any;
  rowDataManual: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isVisible: any;
  public modules: Module[] = AllModules;
  formGroup: FormGroup;
  manualReportForm: FormGroup;

  lines = [];

  constructor(
    private router: Router,
    private prodService: ProdService,
    private fb: FormBuilder
  ) {
  }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = [];
  public barChartLabels1 = [];
  public barChartType = 'bar';
  public barChartType1 = 'bar';
  public barChartLegend = true;
  colorTheme = [];
  layoutPieData = [];
  analogPieData = [];

  columnDefs = [
    {headerName: 'Filial', field: 'branch', sortable: true, width: 200},
    {headerName: 'Nomi', field: 'name', sortable: true, width: 250},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, filter: 'agTextColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Dona', field: 'dona', sortable: true, filter: 'agTextColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererQuantity}
  ];

  columnDefsManual = [
    {headerName: 'Filial', field: 'branch', sortable: true, width: 200},
    {headerName: 'Nomi', field: 'name', sortable: true, width: 250},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, filter: 'agTextColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererQuantity},
    {headerName: 'Dona', field: 'dona', sortable: true, filter: 'agTextColumnFilter', width: 150,
      cellRenderer: CurrencyCellRendererQuantity}
  ];

  public barChartData = [
    {data: this.layoutPieData, label: 'Liniyalar uchun kun statistikasi', backgroundColor: this.colorTheme}
  ];
  public barChartData1 = [
    {data: this.analogPieData, label: 'Analog ishlab chiqarish uchun kun statistikasi', backgroundColor: this.colorTheme}
  ];

  ngOnInit(): void {
    const date1 = new Date();
    const to = dateParser(date1);
    const mm = String(date1.getMonth() + 1).padStart(2, '0');
    const yyyy = date1.getFullYear();
    const from =  yyyy + '-' + mm + '-' + '01';
    this.formGroup = this.fb.group({
      from: [from, Validators.required],
      to: [to, Validators.required]
    });

    this.manualReportForm = this.fb.group({
      line: ['', Validators.required],
      from: [from, Validators.required],
      to: [to, Validators.required]
    });

    this.onSubmit();

    this.prodService.getLine()
      .subscribe((result: Line[]) => {
        this.lines = result;
      });
    // this.manualReportForm.controls.line.patchValue(this.lines[0].id);
    // this.onSubmitManualReport();
  }

  productionLine() {
    this.router.navigate(['../production/line']);
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.prodService.getProductionReport(this.formGroup.value)
        .subscribe(result => {
          this.rowData = result;
          result.forEach(x => {
            this.barChartLabels.push(x.name.substring(0, 9));
            this.layoutPieData.push(x.quantity);
            const r = Math.floor(Math.random() * 200);
            const g = Math.floor(Math.random() * 200);
            const b = Math.floor(Math.random() * 200);
            const color = 'rgb(' + r + ', ' + g + ', ' + x.barcode + ')';
            this.colorTheme.push(color);
          });
        });
    }
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  onSubmitManualReport(): void {
    this.rowDataManual = [];
    this.prodService.getProductionManualReport(this.manualReportForm.value)
      .subscribe(result => {
        this.rowDataManual = result;
        result.forEach(x => {
          this.barChartLabels1.push(x.name.substring(0, 9));
          this.analogPieData.push(x.quantity);
          const r = Math.floor(Math.random() * 200);
          const g = Math.floor(Math.random() * 200);
          const b = Math.floor(Math.random() * 200);
          const color = 'rgb(' + r + ', ' + g + ', ' + x.barcode + ')';
          this.colorTheme.push(color);
        });
      });
  }
}
