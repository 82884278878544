import { Component, OnInit } from "@angular/core";
import { PosService } from "../pos.service";
import { AllModules, Module } from "@ag-grid-enterprise/all-modules";
import { MatDialog } from "@angular/material/dialog";
import { SaleItemsHistoryComponent } from "./sale-items-history/sale-items-history.component";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { dateParser } from "../../dateParser";
import { take } from "rxjs/operators";
import { DebtNoteComponent } from "./debt-note/debt-note.component";

@Component({
  selector: "app-sale-history",
  templateUrl: "./sale-history.component.html",
  styleUrls: ["./sale-history.component.css"],
})
export class SaleHistoryComponent implements OnInit {
  rowData: any;
  rowDataRange: any;
  rowDataDebt: any;
  private rowData1: any;
  date1 = "";
  date2 = "";
  gridApi;
  gridColumnApi;
  defaultColDef;
  gridColumnApiRange;
  defaultColDefRange;
  debtColDefRange;
  public modules: Module[] = AllModules;
  isLoading = false;
  filterForm: FormGroup;
  saleReportForm: FormGroup;
  range: FormGroup;

  totals: any;
  total_given = 0;
  total_calc = 0;
  dollarValue = 0;
  isLoadingDollar = false;

  outcomeSom = 0;
  outcomeDollar = 0;
  outcomeHr = 0;
  totalOutcome = 0;
  hideCashierOutcome = false;
  isProductHistory = false;

  constructor(
    private posService: PosService,
    public dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder
  ) {}

  // Table header names
  columnDefs = [
    // Sale operations table
    {
      headerName: "Sana",
      field: "date",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 170,
    },
    {
      headerName: "Filial",
      field: "branch_name",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
    {
      headerName: "Company",
      field: "company",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 250,
      resizable: true,
      checkboxSelection: true,
    },
    {
      headerName: "Sum",
      field: "totalsum",
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Dollar",
      field: "totaldollar",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 100,
      resizable: true,
      cellRenderer: this.currencyCellRendererSum,
    },
    {
      headerName: "Hr",
      field: "totalhr",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 150,
      resizable: true,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Tolangan sum",
      field: "givensum",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 150,
      resizable: true,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Tolangan dollar",
      field: "givendollar",
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      width: 150,
      cellRenderer: this.currencyCellRendererSum,
    },
    {
      headerName: "Tolangan hr",
      field: "givenhr",
      sortable: true,
      filter: "agNumberColumnFilter",
      resizable: true,
      width: 150,
      valueFormatter: this.currencyFormatter,
    },
    {
      headerName: "Ishchi",
      field: "user",
      width: 100,
      filter: "agTextColumnFilter",
      resizable: true,
    },
    {
      headerName: "Izoh",
      field: "comment",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
  ];

  rangeColumnDefs = [
    {
      headerName: "Nomi",
      field: "client_name",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
    {
      headerName: "Umumiy USD",
      field: "total",
      valueFormatter: this.currencyFormatter,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
    {
      headerName: "So'm",
      field: "sum",
      valueFormatter: this.currencyFormatter,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
    {
      headerName: "Dollar",
      field: "dollar",
      valueFormatter: this.currencyFormatter,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
    {
      headerName: "Hisob raqam (Plastik)",
      field: "hr",
      valueFormatter: this.currencyFormatter,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
  ];

  productColumnDefs = [
    {
      headerName: "Nomi",
      field: "product_name",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
    {
      headerName: "Miqdori",
      field: "quantity",
      valueFormatter: this.currencyFormatter,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
    {
      headerName: "Donasi",
      field: "dona",
      valueFormatter: this.currencyFormatter,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 150,
    },
  ];

  debtColumnDefs = [
    {
      headerName: "Mijoz nomi",
      field: "customer_name",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      checkboxSelection: true,
      width: 300,
    },
    {
      headerName: "Qarz miqdori",
      field: "debt_amount",
      valueFormatter: this.currencyFormatter,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 100,
    },
    {
      headerName: "Sanasi",
      field: "due_date",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 100,
    },
    {
      headerName: "Necha kun qoldi",
      field: "day_difference",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 100,
      cellStyle: this.cellStylingDate,
    },
    {
      headerName: "Qachon qo'shildi",
      field: "cr_date",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 100,
    },
    {
      headerName: "Izoh",
      field: "comment",
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      width: 300,
    },
  ];

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public barChartLabels = [];
  public barChart10Labels = [];
  public barChartSaleLabels = [];
  public barChartType = "bar";
  public barChart10Type = "bar";
  public barChartSaleType = "bar";
  public barChartLegend = true;
  pieData = [];
  barData = [];
  barSaleData = [];
  colorTheme = [];
  maxDate: Date;
  public barChartData = [
    {
      data: this.pieData,
      label: "Kun statistikasi",
      backgroundColor: this.colorTheme,
    },
  ];
  public barChartSaleData = [
    {
      data: this.barSaleData,
      label: "Savdo statistikasi",
      backgroundColor: this.colorTheme,
    },
  ];
  public barChart10Data = [
    {
      data: this.barData,
      label: "Muddat bo'yicha",
      backgroundColor: "#19e60e",
    },
  ];

  currencyFormatter(params) {
    // return '' + this.formatNumber(params.value);
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  onHideCashierOutcomeChange() {
    this.hideCashierOutcome = !this.hideCashierOutcome;
  }

  private currencyCellRendererSum(params: any) {
    const usdFormate = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
    });
    return usdFormate.format(params.value);
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  ngOnInit(): void {
    this.posService
      .getAccountingPermissions()
      .pipe(take(1))
      .subscribe((result) => {
        if (result.appList[2] !== undefined) {
          if (result.appList[2].id === 18) {
            this.isProductHistory = true;
          }
        }
      });

    this.isLoadingDollar = true;
    this.posService
      .getDollarSelf()
      .pipe(take(1))
      .subscribe((result) => {
        this.dollarValue = result[0].currency_value;
        this.isLoadingDollar = false;
      });

    this.rowDataDebt = this.posService.getDebtNotifyers();
    const date1 = new Date();
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const to = dateParser(currentDate);
    const mm = String(date1.getMonth() + 1).padStart(2, "0");
    const yyyy = date1.getFullYear();
    const from = yyyy + "-" + mm + "-" + "01";
    // getting sales repost date form localstorage
    const srD1 = localStorage.getItem("sr_d1");
    const srD2 = localStorage.getItem("sr_d2");

    if (srD1 && srD2) {
      this.saleReportForm = this.fb.group({
        from: [srD1, Validators.required],
        to: [srD2, Validators.required],
      });
    } else {
      this.saleReportForm = this.fb.group({
        from: [from, Validators.required],
        to: [to, Validators.required],
      });
    }

    this.date1 = localStorage.getItem("s_d1");
    this.date2 = localStorage.getItem("s_d2");
    this.filterForm = this.fb.group({
      s_date: [Validators.required],
      e_date: [Validators.required],
    });
    this.isLoading = true;
    const app = {
      d1: localStorage.getItem("s_d1"),
      d2: localStorage.getItem("s_d2"),
    };
    this.onSubmitFilter();
    const a = new Date();
    this.rowData = this.posService.getSaleHistory(a, a, app, true);
    this.posService.getPosSaleDayStat().subscribe((result) => {
      result.forEach((x) => {
        this.pieData.push(x.quantity);
        this.barChartLabels.push(x.name.substring(0, 9));
        const r = Math.floor(Math.random() * 200);
        const g = Math.floor(Math.random() * 200);
        const b = Math.floor(Math.random() * 200);
        const color = "rgb(" + r + ", " + g + ", " + x.barcode + ")";
        this.colorTheme.push(color);
      });
    });
    this.posService
      .getPosSale10DayStat(this.saleReportForm.value)
      .subscribe((result) => {
        this.posService.sentData(this.saleReportForm.value);
        // this.isLoading = false;
        result.forEach((x) => {
          this.barData.push(x.quantity);
          this.barChart10Labels.push(x.name.substring(0, 10));
        });
      });

    this.initClientReportForm();
  }

  cellStylingDate(params: any) {
    if (params.data.day_difference <= 1) {
      return { backgroundColor: "#f08a90", color: "white" };
    } else if (params.data.day_difference > 1) {
      return { backgroundColor: "#28a745", color: "white" };
    }
  }

  initClientReportForm(): void {
    this.range = this.fb.group({
      start: [Validators.required],
      end: [Validators.required],
    });
  }

  onBtnExport() {
    this.gridApi.exportDataAsExcel();
  }

  onRowDoubleClick1() {
    this.rowData1 = 0;
    this.rowData1 = this.gridApi.getSelectedRows()[0];
    if (this.gridApi.getSelectedRows().length > 0) {
      const dialogRef = this.dialog.open(SaleItemsHistoryComponent, {
        width: "60%",
        height: "100%",
        position: { right: "0" },
        data: this.rowData1,
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      alert("Maxsulot tanlang");
    }
  }

  printCheck() {
    if (this.gridApi.getSelectedRows()[0].id > 0) {
      this.router.navigate(["../pos/receiptCheck"], {
        state: { alarm: this.gridApi.getSelectedRows()[0].id },
      });
    } else {
      alert("Check chiqarish uchun savdoni tanlang!");
    }
  }

  onSubmit(): void {
    if (this.filterForm.valid) {
      const app = {
        d1: localStorage.getItem("s_d1"),
        d2: localStorage.getItem("s_d2"),
      };
      this.rowData = this.posService.getSaleHistory(
        this.filterForm.value.s_date,
        this.filterForm.value.e_date,
        app,
        false
      );
      this.date1 = localStorage.getItem("sp_d1");
      this.date2 = localStorage.getItem("sp_d2");
    }
  }

  onSubmitFilter(): void {
    if (this.saleReportForm.valid) {
      this.isLoading = true;
      let payload = {
        id: 52,
        from: dateParser(this.saleReportForm.value.from),
        to: dateParser(this.saleReportForm.value.to),
      };
      localStorage.setItem("sr_d1", payload.from);
      localStorage.setItem("sr_d2", payload.to);
      this.posService
        .postRequest(payload, true)
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res.payload) {
            this.total_given = 0;
            this.total_calc = 0;

            this.totals = res.payload;
            if (res.payload.length > 0) {
              this.totals.profit = res.payload[0].profit;
              this.outcomeSom = res.payload[0].outcome_sum;
              this.outcomeDollar = res.payload[0].outcome_dollar;
              this.outcomeHr = res.payload[0].outcome_hr;
              this.totalOutcome = res.payload[0].total_outcome;

              res.payload.forEach((x) => {
                this.barSaleData.push(x.total_calc);
                this.barChartSaleLabels.push(x.kun);
                const r = Math.floor(Math.random() * 200);
                const g = Math.floor(Math.random() * 200);
                const b = Math.floor(Math.random() * 200);
                const color = "rgb(" + r + ", " + g + ", " + x.total_sum + ")";
                this.colorTheme.push(color);

                this.total_given += Number(x.total_given);
                this.total_calc += Number(x.total_calc);

                this.isLoading = false;
              });
            }
          }
        });
      this.posService.sentData(this.saleReportForm.value);
      this.posService
        .getPosSale10DayStat(this.saleReportForm.value)
        .subscribe((result) => {
          this.isLoading = false;
          result.forEach((x) => {
            this.barData.push(x.quantity);
            this.barChart10Labels.push(x.name.substring(0, 10));
          });
        });
    }
  }

  onReturn() {
    if (this.gridApi.getSelectedRows()[0].id > 0) {
      const id = this.gridApi.getSelectedRows()[0].id;
      this.isLoading = true;
      // getting layout quantity
      this.posService
        .getCheckInfoWithLayoutQuantity(id)
        .subscribe((checkInfo) => {
          this.isLoading = false;
          if (checkInfo.message) {
            if (confirm("Rostdan qaytarmoqchimisiz?")) {
              this.isLoading = true;
              this.posService.posReturnSale(id).subscribe((result) => {
                if (result.message === "1") {
                  this.router.navigate(["/pos"], { state: [checkInfo.data] });
                }
              });
            }
          }
        });
    } else {
      alert("Savdoni tanlang!");
    }
  }

  onDateSelected(date: Date) {
    this.filterForm.controls.e_date.reset();
    this.maxDate = new Date(date);
    this.maxDate.setDate(this.maxDate.getDate() + 31);
  }

  onSubmitRangeClientReport(): void {
    if (this.range.valid) {
      this.posService
        .getClientReport(
          this.range.value.start,
          this.range.value.end,
          "crm/pos/client/report"
        )
        .pipe(take(1))
        .subscribe((result) => {
          this.rowDataRange = result;
        });
    } else {
      alert("Oraliq muddatni tanlang!");
    }
  }

  addNew(): void {
    const dialogRef = this.dialog.open(DebtNoteComponent, {
      width: "50%",
      height: "100%",
      position: { right: "0" },
      data: null,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.rowDataDebt = this.posService.getDebtNotifyers();
    });
  }
  onDeleteDebtNote() {
    if (this.gridApi.getSelectedRows().length > 0) {
      if (confirm("Rostdan o'chirmoqchimisiz?")) {
        this.isLoading = true;
        this.posService
          .deleteDebtNote(this.gridApi.getSelectedRows()[0].id)
          .pipe(take(1))
          .subscribe((result) => {
            this.isLoading = false;
            if (result.message === "1") {
              this.rowDataDebt = this.posService.getDebtNotifyers();
            }
          });
      }
    } else {
      alert("Jadvaldan tanlang!");
    }
  }

  onUpdateDebtNote() {
    if (this.gridApi.getSelectedRows().length > 0) {
      console.log(this.gridApi.getSelectedRows()[0]);
      const dialogRef = this.dialog.open(DebtNoteComponent, {
        width: "50%",
        height: "100%",
        position: { right: "0" },
        data: this.gridApi.getSelectedRows()[0],
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.rowDataDebt = this.posService.getDebtNotifyers();
      });
    } else {
      alert("Jadvaldan tanlang!");
    }
  }
}
