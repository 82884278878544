import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { HrService } from "../hr.service";
import { Store } from "@ngrx/store";
import { Ingredient } from "../../system/store/shopping-list.reducer";
import * as ShoopingListActions from "../../system/store/shooping-list.actions";

@Component({
  templateUrl: "./hr-create.component.html",
  styleUrls: ["./hr-create.component.css"],
})
export class HrCreateComponent implements OnInit {
  @ViewChild("f") formData: NgForm;

  userForm: FormGroup;
  isLoading = false;
  data;
  id = 0;

  constructor(
    private http: HttpClient,
    private router: Router,
    private hrService: HrService,
    private fb: FormBuilder,
    private store: Store<{ shoopingList: { ingredients: Ingredient[] } }>
  ) {}

  ngOnInit() {
    this.data = window.history.state.alarm;
    this.userForm = this.fb.group({
      full_name: ["", [Validators.required, Validators.maxLength(100)]],
      phone_number: ["", [Validators.required, Validators.maxLength(15)]],
      email: ["test@gmail.com", [Validators.email, Validators.required]],
      birth_date: ["", Validators.required],
      salary: ["", [Validators.required, Validators.maxLength(15)]],
    });

    if (window.history.state.alarm !== undefined) {
      this.id = this.data.id;
      this.userForm.controls.full_name.setValue(
        window.history.state.alarm.full_name
      );
      this.userForm.controls.phone_number.setValue(
        window.history.state.alarm.phone_number
      );
      this.userForm.controls.email.setValue(window.history.state.alarm.email);
      this.userForm.controls.birth_date.setValue(
        window.history.state.alarm.birth_date
      );
      this.userForm.controls.salary.setValue(window.history.state.alarm.salary);
    }
  }

  onSubmit(): void {
    if (this.userForm.valid) {
      this.isLoading = true;
      this.hrService
        .addPerson(this.userForm.value, this.userForm.value.birth_date, this.id)
        .subscribe((result) => {
          if (result.message === 1) {
            this.isLoading = false;
            this.store.dispatch(
              new ShoopingListActions.AddIngredient({
                name: "Olma",
                quantity: 2,
              })
            );
            this.userForm.reset("");
          }
        });
    }
  }
}
