import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, take } from "rxjs/operators";
import { InventoryService } from "../../inventory/inventory.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { PosService } from "../pos.service";

interface Customer {
  id: number;
  name: string;
}

interface Balance {
  owner: number;
  branch: number;
  company: number;
  type: number;
  cost: number;
}

@Component({
  templateUrl: "./customer-add.component.html",
  styleUrls: ["./customer-add.component.css"],
})
export class CustomerAddComponent implements OnInit {
  customerForm: FormGroup;
  customerAddForm: FormGroup;
  customerControl = new FormControl();
  filterProducts: Observable<Customer[]>;
  customer: Customer[] = [];
  users: [];
  sum = 0;
  dollar = 0;
  hr = 0;
  cashBack = 0;
  sale = 0;
  isDisable = false;
  productIdArray = [];
  originalSum = 0;
  isLoading = false;
  panelOpenState = false;
  currencyDollar = 0;
  equvDollar = 0;
  equvSum = 0;
  changeSum = 0;
  changeRadioButton = false;
  customerDialog;
  constructor(
    public formBuilder: FormBuilder,
    private inventoryService: InventoryService,
    private dialogRef: MatDialogRef<CustomerAddComponent>,
    private posService: PosService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.originalSum = this.data.originalCostSum;
    this.customerForm = this.formBuilder.group({
      id: [0, Validators.required],
      name: ["", Validators.required],
      paidSum: [0, [Validators.required, Validators.maxLength(15)]],
      paidDollar: [0, [Validators.required, Validators.maxLength(15)]],
      paidHr: [0, [Validators.required, Validators.maxLength(15)]],
      totalSumma: [
        this.data.sale.totalSum.toFixed(2),
        [Validators.required, Validators.maxLength(15)],
      ],
      totalPlastic: [
        this.data.sale.totalHr.toFixed(2),
        [Validators.required, Validators.maxLength(15)],
      ],
      totalDollar: [
        this.data.sale.totalDollar.toFixed(2),
        [Validators.required, Validators.maxLength(15)],
      ],
      isChange: [false, [Validators.required]],
      delivery: [0, Validators.required],
      comment: ["Izoh", [Validators.required, Validators.maxLength(100)]],
      sms: [1, [Validators.required]],
      isDebt: [false, [Validators.required]],
      debtDate: [new Date()],
      debtSum: [0, [Validators.maxLength(15)]],
    });
    this.inventoryService.getOwnerUsers().subscribe((result) => {
      this.users = result;
    });
    this.customerAddForm = this.formBuilder.group({
      name1: ["", [Validators.required, Validators.maxLength(100)]],
      phone: [
        "",
        [
          Validators.required,
          Validators.maxLength(12),
          Validators.minLength(12),
        ],
      ],
    });
    this.filterProducts = this.customerControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filter(value))
    );
    this.inventoryService.getCompany().subscribe((result: Customer[]) => {
      this.customer = result;
    });

    this.posService
      .getDollarSelf()
      .pipe(take(1))
      .subscribe((r) => {
        this.currencyDollar = r[0].currency_value;
        this.equvDollar = this.data.sale.totalSum / this.currencyDollar;
        this.equvSum = this.data.sale.totalDollar * this.currencyDollar;
      });
  }

  filter(val: any): Customer[] {
    return this.customer.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === "object") {
        val = "";
      } else {
        const TempString = item.name;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.name;
  }

  setProduct(event: any) {
    this.isLoading = true;
    this.customerForm.controls.id.setValue(event.id);
    this.customerForm.controls.name.setValue(event.name);
    localStorage.setItem("temp_cl_phone", event.phone_number);
    this.inventoryService
      .getBalance(event.id)
      .subscribe((result: Balance[]) => {
        // this.balance = result;
        this.sum = result[0].cost;
        this.hr = result[1].cost;
        this.dollar = result[2].cost;
        if (result.length > 4) {
          this.cashBack = result[4].cost;
        }
        this.isLoading = false;
      });
  }

  onSubmit() {
    if (confirm("Savdoni amalga oshirasizmi?")) {
      let saleAble = true;
      if (this.customerForm.valid) {
        this.data.sale.products.forEach((product) => {
          if (product.product_id > 0 && product.product_id !== null) {
          } else {
            saleAble = false;
            alert(
              "Xatolik yuz berdi maxsulotlarni ochirib boshqatdan kiriting"
            );
          }
        });
        this.isDisable = true;
        if (saleAble) {
          this.posService
            .postSale(
              this.customerForm.value.id,
              this.customerForm.value.totalSumma,
              this.customerForm.value.totalPlastic,
              this.customerForm.value.totalDollar,
              this.customerForm.value.paidSum,
              this.customerForm.value.paidHr,
              this.customerForm.value.paidDollar,
              this.data.sale.products,
              this.data.originalCostSum,
              Number(this.data.sale.totalSum) + Number(this.data.sale.totalHr),
              this.customerForm.value.delivery,
              this.customerForm.value.comment,
              Number(this.customerForm.value.sms),
              this.changeSum,
              this.customerForm.value.isChange,
              this.customerForm.value.isDebt,
              this.customerForm.value.debtDate,
              this.customerForm.value.debtSum
            )
            .subscribe((result) => {
              if (result.message === "1") {
                this.isDisable = false;
                this.dialogRef.close({ a: 1, id: result.id });
              }
            });
        } else {
          this.onClose();
        }
      } else {
        alert("Barcha malumotlarni kiriting!");
      }
    }
  }

  addCustomer() {
    if (this.customerAddForm.valid) {
      this.posService
        .addCustomer(
          this.customerAddForm.value.name1,
          this.customerAddForm.value.phone
        )
        .subscribe((result) => {
          console.log(result);

          if (result.message === "1") {
            this.customerDialog.close();
            this.ngOnInit();
            this.customerForm.controls.id.setValue(result["company_id"]);
            this.customerForm.controls.name.setValue(result["company_name"]);
            this.customerControl.setValue({
              id: result["company_id"],
              name: result["company_name"],
            });
          }
        });
    } else {
      alert("Barcha malumotlarni kiriting!");
    }
  }

  saveTemplate(templateRef: any): void {

    this.customerDialog = this.dialog.open(templateRef, {
      width: "500px",
      height: "350px",
    });
  }

  onIconClick() {
    this.customerForm.controls.paidSum.setValue(
      this.customerForm.value.totalSumma
    );
    this.customerForm.controls.paidDollar.setValue(
      this.customerForm.value.totalDollar
    );
    this.customerForm.controls.paidHr.setValue(
      this.customerForm.value.totalPlastic
    );
    this.onPaidDollarEntered();
  }

  onNobodyClicked() {
    this.customerForm.controls.id.setValue(0);
    this.customerForm.controls.name.setValue(0);
    localStorage.setItem("temp_cl_phone", "");
    this.onIconClick();
    this.onSubmit();
    this.customerForm.reset();
  }

  onSale() {
    this.onNobodyClicked();
  }

  onSaleEntered() {
    const summa: number = this.customerForm.value.totalSumma;
    this.equvDollar = summa / this.currencyDollar;
    this.sale =
      Number(this.data.sale.totalSum) +
      Number(this.data.sale.totalHr) -
      (Number(this.customerForm.value.totalSumma) +
        Number(this.customerForm.value.totalPlastic));

    this.onPaidDollarEntered();
  }

  onDollarEntered(): void {
    const summa: number = this.customerForm.value.totalDollar;
    this.equvSum = summa * this.currencyDollar;
  }

  onPaidDollarEntered(): void {
    this.changeSum = 0;
    if (
      this.customerForm.value.paidDollar > 0 ||
      this.customerForm.value.paidSum > 0 ||
      this.customerForm.value.paidHr > 0
    ) {
      const paidDollar: number = this.customerForm.value.paidDollar;
      const paidSum: number = this.customerForm.value.paidSum;
      const totalSum: number = this.customerForm.value.totalSumma;

      this.changeSum =
        Number(paidDollar) * Number(this.currencyDollar) +
        Number(paidSum) -
        Number(totalSum);
    } else {
      this.changeSum = 0;
    }
  }

  onChangeRadioButton(id: number): void {
    if (id === 1) {
      this.changeRadioButton = true;
    } else {
      this.changeRadioButton = false;
    }
    console.log(this.customerForm.value.isChange);
  }

  onClose() {
    this.dialogRef.close({ a: 2 });
  }
}

