import { Component, OnInit } from "@angular/core";
import { AllModules, Module } from "@ag-grid-enterprise/all-modules";
import { PosService } from "../../pos.service";
import { Router } from "@angular/router";
import { CurrencyCellRendererQuantity } from "../../../../test/table-currency";

@Component({
  templateUrl: "./day-stat.component.html",
  selector: "app-day-stat",
})
export class DayStatComponent implements OnInit {
  constructor(
    private posService: PosService,
    private router: Router
  ) {}
  isLoading = false;
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  isVisible: any;
  public modules: Module[] = AllModules;

  columnDefs = [
    {
      headerName: "Nomi",
      field: "name",
      sortable: true,
      width: 300,
      filter: "agTextColumnFilter",
      checkboxSelection: true,
    },
    {
      headerName: "Barcode",
      field: "barcode",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Miqdori",
      field: "quantity",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 150,
      cellRenderer: CurrencyCellRendererQuantity,
    },
    {
      headerName: "Dona",
      field: "dona",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 150,
      cellRenderer: CurrencyCellRendererQuantity,
    },
    {
      headerName: "Summa",
      field: "total_sum",
      sortable: true,
      filter: "agNumberColumnFilter",
      width: 150,
      cellRenderer: CurrencyCellRendererQuantity,
    },
  ];

  ngOnInit(): void {
    if (this.router.url === "/production") {
    } else if (this.router.url === "/sale_history") {
      this.rowData = this.posService.getPosSaleDayStat();
    }
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  onDoubleClicked() {
    this.gridApi.exportDataAsExcel();
  }
}
