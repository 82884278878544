import {Component, OnInit} from '@angular/core';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {PosService} from '../../pos.service';
import {CurrencyCellRendererQuantity} from '../../../../test/table-currency';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {take} from 'rxjs/operators';

@Component({
  templateUrl: './sale-products.component.html',
  styleUrls: ['./sale-products.component.css'],
  selector: 'app-sale-products'
})
export class SaleProductsComponent implements OnInit {

  filterForm: FormGroup;
  public rowDataSaleProducts: any;
  quantity;
  saleSum;
  tanSum;
  date1 = '';
  date2 = '';
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  maxDate: Date;

  totalSale = 0;
  totalTannarx = 0;
  diff = 0;
  isLoading = false;

  isProductHistory = false;
  constructor(
    private posService: PosService,
    private fb: FormBuilder
  ) {
  }
  columnDefs1 = [
    {headerName: 'Sana', field: 'cr_on', sortable: true, filter: 'agTextColumnFilter', width: 170, resizable: true},
    {headerName: 'Filial', field: 'branch_name', sortable: true, filter: 'agTextColumnFilter', resizable: true, width: 200},
    {headerName: 'Mijoz', field: 'customer', sortable: true, filter: 'agTextColumnFilter', resizable: true, width: 200},
    {headerName: 'Barcode', field: 'barcode', sortable: true, filter: 'agTextColumnFilter', resizable: true, width: 200},
    {headerName: 'Nomi', field: 'name', sortable: true, filter: 'agTextColumnFilter', width: 300},
    {headerName: 'Valyuta', field: 'currency1', sortable: true, filter: 'agTextColumnFilter', width: 150},
    {headerName: 'Miqdori', field: 'quantity', sortable: true, filter: 'agNumberColumnFilter', width: 150,
      valueFormatter: CurrencyCellRendererQuantity
    },
    {headerName: 'Narx', field: 'cost', sortable: true, filter: 'agNumberColumnFilter',
      width: 100, resizable: true, cellRenderer: CurrencyCellRendererQuantity
    },
    {headerName: 'Jami', field: 'sum', sortable: true, filter: 'agNumberColumnFilter',
      width: 100, resizable: true, cellRenderer: CurrencyCellRendererQuantity
    },
    {headerName: 'Jami tannarx', field: 'sum_uz', sortable: true, filter: 'agNumberColumnFilter',
      width: 100, resizable: true, cellRenderer: CurrencyCellRendererQuantity
    },
    {headerName: 'Tan-narx jami $', field: 'sum_us', sortable: true, filter: 'agNumberColumnFilter',
      width: 150, resizable: true, cellRenderer: CurrencyCellRendererQuantity
    },
    {headerName: 'Izoh', field: 'comment_s', sortable: true, filter: 'agTextColumnFilter', width: 150},
  ];

  currencyFormatter(params) {
    // return '' + this.formatNumber(params.value);
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  onBtnExport(agGrid: any) {
    agGrid.exportDataAsExcel();
  }

  ngOnInit(): void {
    this.date1 = localStorage.getItem('sp_d1');
    this.date2 = localStorage.getItem('sp_d2');
    this.filterForm = this.fb.group({
      s_date: [Validators.required],
      e_date: [Validators.required],
    });
    const app = {
      d1: localStorage.getItem('sp_d1'),
      d2: localStorage.getItem('sp_d2'),
    };
    const  a = new Date();
    this.posService.getPosSaleProducts(a, a, app, true).pipe(take(1))
      .subscribe(r => {
        this.rowDataSaleProducts = r;
        this.calculateTotalSums();
      });

    this.posService.getAccountingPermissions().pipe(take(1))
      .subscribe(result => {
        if (result.appList[2] !== undefined) {
          if (result.appList[2].id === 18) {
            this.isProductHistory = true;
          }
        }
      });
  }

  onSubmit(): void {
    if (this.filterForm.valid) {
      this.isLoading = true;
      const app = {
        d1: localStorage.getItem('sp_d1'),
        d2: localStorage.getItem('sp_d2'),
      };
      this.posService.getPosSaleProducts(this.filterForm.value.s_date, this.filterForm.value.e_date, app, false).pipe()
        .subscribe(r => {
          this.isLoading = false;
          this.rowDataSaleProducts = r;
          this.calculateTotalSums();
        });
      this.date1 = localStorage.getItem('sp_d1');
      this.date2 = localStorage.getItem('sp_d2');
    } else {
      alert('Oraliq muddatni kiriting!');
    }
  }

  onDateSelected(date: Date) {
    this.filterForm.controls.e_date.reset();
    this.maxDate = new Date(date);
    this.maxDate.setDate(this.maxDate.getDate() + 31);
  }

  calculateTotalSums() {
    this.totalSale = 0;
    this.totalTannarx = 0;
    this.diff = 0;
    for (let i = 0; i < this.rowDataSaleProducts.length; i++) {
      this.totalSale = this.totalSale + Number(this.rowDataSaleProducts[i].sum);
      this.totalTannarx = this.totalTannarx + Number(this.rowDataSaleProducts[i].sum_uz);
    }
    this.diff = this.totalSale - this.totalTannarx;
  }
}
