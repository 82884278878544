import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PosService } from "../../pos.service";
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from "@techiediaries/ngx-qrcode";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";

interface Branch {
  id: number;
  name: number;
  address: string;
  email: string;
  phone: string;
  website: string;
  check_number: string;
}

interface Sale {
  id: number;
  totalsum: number;
  totaldollar: number;
  totalhr: number;
  givensum: number;
  givendollar: number;
  givenhr: number;
  date: string;
  products: any;
  first_sale: string;
  second_sale: string;
  company: string;
  sum_balance: number;
  hr_balance: number;
  dollar_balance: number;
}

@Component({
  templateUrl: "./sale-check.component.html",
  styleUrls: ["./sale-check.component.css"],
})
export class SaleCheckComponent implements OnInit, OnDestroy {
  branch: Branch[] = [];
  sales: Sale;
  items: any[] = [];
  isLoading = false;
  totalQuantity = 0;
  totalDona = 0;
  currentDate: string;
  currentTime: string;
  discount = 0;

  totalsum = 0;
  totaldollar = 0;
  totalhr = 0;
  givensum = 0;
  givendollar = 0;
  givenhr = 0;
  sumBalance = 0;
  hrBalance = 0;
  dollarBalance = 0;
  elementType = NgxQrcodeElementTypes.IMG;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "";
  itemList = [];
  company = "";
  today = "";
  tempPhone = "";
  bayram = "";

  constructor(
    private router: Router,
    private posService: PosService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.currentDate = new Date().toDateString();
    this.currentTime = new Date().toTimeString().substring(0, 8);
    this.posService.getBranchInfo().subscribe((result: Branch[]) => {
      this.branch = result;
    });
    let id = 0;
    if (window.history.state.alarm > 0) {
      id = window.history.state.alarm;
    } else {
      id = 0;
    }
    const date = new Date();
    const day = date.getDate();
    const month = 1 + Number(date.getMonth());
    const year = date.getFullYear();
    this.today = day + "." + month + "." + year;

    this.posService
      .getCheckInfoProducts(id)
      .pipe(take(1))
      .subscribe((data) => {
        this.items = data;

        this.posService.getSaleCheck(id).subscribe((result: Sale) => {
          this.isLoading = false;
          this.sales = result;
          this.company = result.company;
          this.tempPhone = localStorage.getItem("temp_cl_phone");
          if (result.company === null) {
            this.company = localStorage.getItem("temp_cl"); // getting temporary client from local storage
          }
          this.totalsum = result.totalsum;
          this.totaldollar = result.totaldollar;
          this.totalhr = result.totalhr;
          this.givensum = result.givensum;
          this.givendollar = result.givendollar;
          this.givenhr = result.givenhr;
          this.value +=
            this.company +
            ": " +
            "So'm: " +
            result.sum_balance +
            ", " +
            "Hr: " +
            result.hr_balance +
            ", " +
            "Dollar: " +
            result.dollar_balance;

          // this.items = result.products;

          // this.items = result.products;
          this.discount =
            Number(this.sales.first_sale) + Number(this.sales.second_sale);
          let i = 0;
          for (i = 0; i < this.items.length; i += 1) {
            this.totalQuantity += Number(this.items[i].quantity);
            this.totalDona += Number(this.items[i].dona);
          }
        });
      });
  }

  backSale() {
    this.router.navigate(["../pos"]);
  }

  open(content, id: number) {
    if (id === 1) {
      this.modalService.open(content, { size: "sm" }).result.then(
        (result) => {},
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    } else if (id === 3) {
      this.modalService.open(content, { size: "sm" }).result.then(
        (result) => {},
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    } else if (id === 4) {
      this.modalService.open(content, { size: "sm" }).result.then(
        (result) => {},
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    } else if (id === 5) {
      this.modalService.open(content, { size: "sm" }).result.then(
        (result) => {},
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    } else if (id === 6) {
      this.modalService.open(content, { size: "sm" }).result.then(
        (result) => {},
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    } else {
      this.modalService
        .open(content, { windowClass: "myCustomModalClass", size: "xl" })
        .result.then(
          (result) => {},
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  print(name: string) {
    // window.print();
    const printContent = document.getElementById(name);
    let htmlPrint =
      "" +
      "<style>" +
      ".img-qrcode > img{" +
      "display: none;" +
      " width: 150px;" +
      "height: 150px;" +
      "margin-left: 20px;" +
      "margin-right: 20px;" +
      'font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;' +
      "font-size: 17px;" +
      "text-align: center;" +
      "}" +
      "#legalcopy > p {" +
      "text-align: center;" +
      "}" +
      ".info > h2, p {" +
      "text-align: center;" +
      "}" +
      "ngx-qrcode {" +
      "margin: auto;" +
      "}" +
      "code {" +
      "font-size: 16px" +
      "}" +
      "table th {" +
      "border-bottom: 0.5px solid #000;" +
      "padding: 2px;" +
      "}" +
      "table td {" +
      "border-bottom: 0.5px solid #000;" +
      "border-right: 0.5px solid #000;" +
      "padding: 1px" +
      "}" +
      "h1 {" +
      "text-align: center" +
      "}" +
      ".header-text {" +
      "display: flex;" +
      "justify-content: space-between;" +
      "align-items: center;" +
      " line-height: 2px;" +
      "}" +
      "p {" +
      "font-size: 14px;" +
      "}" +
      "table {" +
      "width: 100%;" +
      "}" +
      ".text-center {" +
      "text-align: center;" +
      "}" +
      ".text-info {" +
      "line-height: 15px !important;" +
      "}" +
      "</style>";
    htmlPrint += printContent.outerHTML;
    const WindowPrt = window.open();
    WindowPrt.document.write(htmlPrint);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  printA4(name: string) {
    // window.print();
    const printContent = document.getElementById(name);
    let htmlToPrint =
      "" +
      '<style type="text/css">' +
      "table th {" +
      "border-bottom: 1px solid #000;" +
      "padding: 2px;" +
      "}" +
      "table td {" +
      "border-bottom: 1px solid #000;" +
      "border-right: 1px solid #000;" +
      "padding: 2px" +
      "}" +
      ".img-qrcode > img{" +
      "width: 150px;" +
      "height: 150px;" +
      "margin-left: 20px;" +
      "margin-right: 20px;" +
      "}" +
      ".text-center: {" +
      "text-align: center;" +
      "font-size: 15px;" +
      "}" +
      "#footer-items {" +
      "display: flex;" +
      "justify-content: space-between;" +
      "}" +
      "#a4 {" +
      "display: flex;" +
      "flex-direction: column;" +
      "align-items: center;" +
      "}" +
      "</style>";
    const WindowPrt = window.open();
    htmlToPrint += printContent.outerHTML;
    WindowPrt.document.write(htmlToPrint);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  ngOnDestroy() {
    localStorage.setItem("temp_cl_phone", "");
  }
}
