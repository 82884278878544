import { Component, OnInit } from "@angular/core";
import { AllModules, Module } from "@ag-grid-enterprise/all-modules";
import { InventoryService } from "../../inventory.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { dateParser } from "../../../dateParser";
import { take } from "rxjs/operators";
import { CurrencyCellRendererQuantity } from "../../../../test/table-currency";

@Component({
  templateUrl: "./inventory-history.component.html",
  styleUrls: ["inventory-history.component.css"],
})
export class InventoryHistoryComponent implements OnInit {
  inventoryHistoryForm: FormGroup;
  gridApi;
  gridColumnApi;
  defaultColDef;
  rowData1: any;
  rowData: any;
  products: any = [];
  public modules: Module[] = AllModules;
  id;
  typeForm: FormGroup;
  type = [];
  typeQuantity = 0;

  constructor(
    private inventoryService: InventoryService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: ActivatedRoute
  ) {
    this.defaultColDef = { resizable: true };
  }

  columnDefsReport = [
    {
      headerName: "Sana",
      field: "cr_on",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Sotuv",
      field: "export_quantity",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      cellStyle: (params) => {
        if (params.data.export_quantity > 0) {
          return { color: "white", backgroundColor: "red" };
        } else {
          return { color: "white", backgroundColor: "green" };
        }
      },
    },
    {
      headerName: "Savdodan qaytarilgan",
      field: "savdodan_qaytarilgan",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
    },
    {
      headerName: "Ombor kirimi",
      field: "import_quantity",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      cellStyle: (params) => {
        if (params.data.import_quantity > 0) {
          return { color: "white", backgroundColor: "green" };
        } else {
          return { color: "white", backgroundColor: "green" };
        }
      },
    },
    {
      headerName: "Ombor chiqimi",
      field: "qayta_quantity",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      cellStyle: (params) => {
        if (params.data.qayta_quantity > 0) {
          return { color: "white", backgroundColor: "red" };
        } else {
          return { color: "white", backgroundColor: "green" };
        }
      },
    },
    {
      headerName: "Ishlab chiqarish",
      field: "p_h",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      cellStyle: (params) => {
        if (params.data.p_h > 0) {
          return { color: "white", backgroundColor: "green" };
        } else {
          return { color: "white", backgroundColor: "green" };
        }
      },
    },
    {
      headerName: "Ishlab chiqarish sarfi",
      field: "p_h_i",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      cellStyle: (params) => {
        if (params.data.p_h_i > 0) {
          return { color: "white", backgroundColor: "red" };
        } else {
          return { color: "white", backgroundColor: "green" };
        }
      },
    },
    {
      headerName: "Analog ishlab chiqarish",
      field: "p_m",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      cellStyle: (params) => {
        if (params.data.p_m > 0) {
          return { color: "white", backgroundColor: "green" };
        } else {
          return { color: "white", backgroundColor: "green" };
        }
      },
    },
    {
      headerName: "Analog ishlab chiqarish sarfi",
      field: "p_m_i",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      cellStyle: (params) => {
        if (params.data.p_m_i > 0) {
          return { color: "white", backgroundColor: "red" };
        } else {
          return { color: "white", backgroundColor: "green" };
        }
      },
    },
    {
      headerName: "Ertalabki qoldiq",
      field: "morning_quantity",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
    },
  ];
  columnDefs = [
    {
      headerName: "Sana",
      field: "date",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Filial",
      field: "branch_name",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Ombor",
      field: "warehouse",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Barcode",
      field: "barcode",
      sortable: true,
      filter: "agTextColumnFilter",
      checkboxSelection: true,
    },
    {
      headerName: "Turi",
      field: "type",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Nomi",
      field: "product_name",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        applyButton: true,
        resetButton: true,
      },
    },
    {
      headerName: "Sum",
      field: "cost_uz",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: this.CurrencyCellRendererSum,
    },
    {
      headerName: "Sotuv som narxi",
      field: "sell_cost_uz",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: this.CurrencyCellRendererSum,
    },
    {
      headerName: "Dollar",
      field: "cost_us",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: this.CurrencyCellRendererDollar,
    },
    {
      headerName: "Sotuv dollar narxi",
      field: "sell_cost_us",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: this.CurrencyCellRendererDollar,
    },
    {
      headerName: "Miqdori",
      field: "quantity",
      sortable: true,
      filter: "agNumberColumnFilter",
      aggFunc: "sum",
      valueFormatter: this.currencyFormatter,
      cellStyle: this.cellStyling,
    },
    {
      headerName: "Birlik",
      field: "product_unit",
      sortable: true,
      filter: "agTextColumnFilter",
    },
  ];
  // Currency renderer functions
  currencyFormatter(params) {
    // return '' + this.formatNumber(params.value);
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  private CurrencyCellRendererSum(params: any) {
    const usdFormate = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "UZS",
      minimumFractionDigits: 0,
    });
    return usdFormate.format(params.value);
  }
  private CurrencyCellRendererDollar(params: any) {
    const usdFormate = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });
    return usdFormate.format(params.value);
  }
  cellStyling(params: any) {
    if (params.data.quantity < 100 && params.data.quantity >= 50) {
      return { "background-color": "#17A589; color: white;" };
    } else if (params.data.quantity < 50 && params.data.quantity > 10) {
      return { "background-color": "#e8c855; color: black;" };
    } else if (params.data.quantity <= 10) {
      return { "background-color": "#f08a90; color: white;" };
    }
  }
  ngOnInit(): void {
    const date1 = new Date();
    const to = dateParser(date1);
    const mm = String(date1.getMonth() + 1).padStart(2, "0");
    const yyyy = date1.getFullYear();
    const from = yyyy + "-" + mm + "-" + "01";
    this.id = this.router.snapshot.paramMap.get("id");

    this.inventoryHistoryForm = this.fb.group({
      date1: [from, Validators.required],
      date2: [to, Validators.required],
    });

    this.typeForm = this.fb.group({
      type_id: ["", Validators.required],
    });
    this.onSubmit();
    this.getType();
  }
  getType(): any {
    this.inventoryService.getType().subscribe((result) => {
      this.type = result;
    });
  }
  onSubmit() {
    if (this.inventoryHistoryForm.valid) {
      this.rowData1 = this.inventoryService.getInventoryHistory(
        this.id,
        dateParser(this.inventoryHistoryForm.value.date1),
        dateParser(this.inventoryHistoryForm.value.date2)
      );
    } else {
      this.toastr.info("Sanani tanlang!");
    }
  }
  onSubmitType(id: number): void {
    this.inventoryService
      .getInventoryHistoryType(
        id,
        dateParser(this.inventoryHistoryForm.value.date1),
        dateParser(this.inventoryHistoryForm.value.date2)
      )
      .subscribe((result) => {
        this.typeQuantity = result[0].cnt;
        this.getTypeHistoryProducts(id);
      });
  }
  getTypeHistoryProducts(id: number): void {
    if (this.inventoryHistoryForm.valid) {
      this.rowData = this.inventoryService.getTypeHistoryProducts(
        id,
        dateParser(this.inventoryHistoryForm.value.date1),
        dateParser(this.inventoryHistoryForm.value.date2)
      );
    } else {
      this.toastr.info("Sanani tanlang!");
    }
  }
  onSelected(id: number): void {
    this.onSubmitType(id);
  }
}
