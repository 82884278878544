import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../auth/auth.guard";
import { UserCreateComponent } from "./client-create/user-create/user-create.component";
import { ProfileComponent } from "./client-create/user-profile/profile.component";
import { PermissionComponent } from "./permissions/permission.component";
import { PaymentsComponent } from "./payments/payments.component";

const routes: Routes = [
  { path: "system", component: UserCreateComponent, canActivate: [AuthGuard] },
  {
    path: "system/profile",
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "permissions",
    component: PermissionComponent,
    canActivate: [AuthGuard],
  },
  { path: "payments", component: PaymentsComponent, canActivate: [AuthGuard] },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemRoutingModule {}
