import { NgModule } from "@angular/core";
import { CrmRoutingModule } from "./crm-routing.module";
import { InventoryListComponent } from "./inventory/inventory-list/inventory-list.component";
import { AgGridModule } from "ag-grid-angular";
import { CommonModule } from "@angular/common";
import { InvoiceListComponent } from "./inventory/invoice/invoice-list/invoice-list.component";
import { InvoiceAddComponent } from "./inventory/invoice/invoice-add/invoice-add.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CompanyAddComponent } from "./inventory/invoice/company-add/company-add.component";
import { CurrencyAddComponent } from "./inventory/invoice/currency-add/currency-add.component";
import { TypeAddComponent } from "./inventory/invoice/type-add/type-add.component";
import { InventoryAddComponent } from "./inventory/inventory-add/inventory-add.component";
import { TranslateModule } from "@ngx-translate/core";
import { SaleComponent } from "./pos/sale/sale.component";
import { CustomerAddComponent } from "./pos/customer-add/customer-add.component";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CashierDayComponent } from "./cashier/cashier-day/cashier-day.component";
import { SaleHistoryComponent } from "./pos/sale-history/sale-history.component";
import { ChartsModule } from "ng2-charts";
import { WarehouseAddComponent } from "./inventory/invoice/warehouse-add/warehouse-add.component";
import { ProductUpdateComponent } from "./inventory/inventory-list/product-update/product-update.component";
import { CashierSettingsComponent } from "./cashier/cashier-settings/cashier-settings.component";
import { SaleCheckComponent } from "./pos/sale/sale-check/sale-check.component";
import { AdminOperComponent } from "./Accounting/admin-oper/admin-oper.component";
import { SaleItemsHistoryComponent } from "./pos/sale-history/sale-items-history/sale-items-history.component";
import { ExchangeCurrencyComponent } from "./Accounting/exchange-currency/exchange-currency.component";
import { InvoicePListComponent } from "./inventory/invoice/invoice-list/invoice-product-list/invoice-p-list.component";
import { AdminDashboardComponent } from "./Accounting/dashboard/admin-dashboard.component";
import { AccountingAddoperationComponent } from "./Accounting/admin-oper/accounting-addoperation/accounting-addoperation.component";
import { CashierOperationsComponent } from "./cashier/cashier-day/cashier-operations/cashier-operations.component";
import { CompanyListComponent } from "./inventory/invoice/company-add/company-list/company-list.component";
import { CloseDayCheckComponent } from "./cashier/cashier-day/close-day-check/close-day-check.component";
import { DayStatComponent } from "./pos/sale-history/day-stat/day-stat.component";
import { Day10StatComponent } from "./pos/sale-history/10day-stat/10day-stat.component";
import { SaleProductsComponent } from "./pos/sale-history/pos-sale-products/sale-products.component";
import { AgGridResizeDirective } from "./tab.directive";
import { InventoryHistoryComponent } from "./inventory/inventory-list/inventory-history/inventory-history.component";
import { CashierBalanceHComponent } from "./cashier/cashier-balance-h/cashier-balance-h.component";
import { InvoiceProductsComponent } from "./inventory/invoice/invoice-list/invoice-products/invoice-products.component";
import { ExcellImportComponent } from "./inventory/inventory-list/product-excell-import/excell-import.component";
import { InPropertyComponent } from "./inventory/invoice/inventory-property/in-property.component";
import { InListComponent } from "./inventory/invoice/invoice-list/in-list/in-list.component";
import { InventoriesComponent } from "./inventory/inventory-list/inventories/inventories.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { ClientReportComponent } from "./cashier/cashier-day/client-report/client-report.component";
import { AngularMaterialModule } from "../angular-material.module";
import { NgxMaskModule } from "ngx-mask";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ArchiveComponent } from "./inventory/inventory-list/archive/archive.component";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { CurrencyExchangeComponent } from "../hospital/components/currency-exchange/currency-exchange.component";
import { DebtNoteComponent } from "./pos/sale-history/debt-note/debt-note.component";
import { NgxBarcode6Module } from "ngx-barcode6";

@NgModule({
  declarations: [
    InventoryListComponent,
    InvoiceListComponent,
    InvoiceAddComponent,
    CompanyAddComponent,
    CurrencyAddComponent,
    TypeAddComponent,
    InventoryAddComponent,
    SaleComponent,
    CustomerAddComponent,
    CashierDayComponent,
    SaleHistoryComponent,
    WarehouseAddComponent,
    ProductUpdateComponent,
    CashierSettingsComponent,
    SaleCheckComponent,
    AdminOperComponent,
    SaleItemsHistoryComponent,
    ExchangeCurrencyComponent,
    InvoicePListComponent,
    AdminDashboardComponent,
    AccountingAddoperationComponent,
    CashierOperationsComponent,
    CompanyListComponent,
    CloseDayCheckComponent,
    DayStatComponent,
    Day10StatComponent,
    SaleProductsComponent,
    InventoryHistoryComponent,
    AgGridResizeDirective,
    CashierBalanceHComponent,
    InvoiceProductsComponent,
    ExcellImportComponent,
    InPropertyComponent,
    InListComponent,
    InventoriesComponent,
    ClientReportComponent,
    ArchiveComponent,
    CurrencyExchangeComponent,
    DebtNoteComponent
  ],
  exports: [DayStatComponent, Day10StatComponent, DebtNoteComponent],
  imports: [
    CrmRoutingModule,
    FormsModule,
    AgGridModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    CurrencyMaskModule,
    ChartsModule,
    NgxQRCodeModule,
    AngularMaterialModule,
    NgxMaskModule,
    MatButtonToggleModule,
    NgbDropdownModule,
    NgxBarcode6Module
  ],
})
export class CrmModule {}
