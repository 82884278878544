import { Component, OnInit } from "@angular/core";
import { AllModules, Module } from "@ag-grid-enterprise/all-modules";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { InventoryService } from "../inventory.service";
import { MatDialog } from "@angular/material/dialog";
import { InventoryAddComponent } from "../inventory-add/inventory-add.component";
import { ProductUpdateComponent } from "./product-update/product-update.component";
import { Observable } from "rxjs";
import {
  CurrencyCellRendererDollar,
  CurrencyCellRendererQuantity,
  CurrencyCellRendererSum,
} from "../../../test/table-currency";
import { ArchiveComponent } from "./archive/archive.component";
import { take } from "rxjs/operators";

interface Warehouse {
  id: number;
  name: string;
  level_nice: number;
  level_warning: number;
  level_low: number;
}

interface Type {
  id: number;
  name: string;
}

@Component({
  templateUrl: "./inventory-list.component.html",
  styleUrls: ["./inventory-list.component.css"],
})
export class InventoryListComponent implements OnInit {
  rowData: any = [];
  warehouse: Warehouse[] = [];
  gridApi;
  gridColumnApi;
  defaultColDef;
  warehouse$: Observable<Warehouse[]>;
  isLoading = false;
  totalCostUz = 0;
  totalCostUs = 0;
  totalSellCostUz = 0;
  totalSellCostUs = 0;
  totalQuantity = 0;
  btnWarehouse = "0";
  searchProduct = "";
  types$: Observable<Type[]>;
  public modules: Module[] = AllModules;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private inventoryService: InventoryService
  ) {
    this.defaultColDef = { resizable: true };
  }

  columnDefs = [
    {
      headerName: "Filial",
      field: "branch_name",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Ombor",
      field: "warehouse",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Barcode",
      field: "barcode",
      sortable: true,
      filter: "agTextColumnFilter",
      checkboxSelection: true,
      width: 150,
    },
    {
      headerName: "Turi",
      field: "type",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 200,
    },
    {
      headerName: "Nomi",
      field: "product_name",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 250,
    },
    {
      headerName: "Sum",
      field: "cost_uz",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: CurrencyCellRendererSum,
      width: 150,
    },
    {
      headerName: "Sotuv som narxi",
      field: "sell_cost_uz",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: CurrencyCellRendererSum,
      width: 150,
    },
    {
      headerName: "Dollar",
      field: "cost_us",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: CurrencyCellRendererDollar,
      width: 100,
    },
    {
      headerName: "Sotuv dollar narxi",
      field: "sell_cost_us",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: CurrencyCellRendererDollar,
      width: 100,
    },
    {
      headerName: "Dona",
      field: "dona",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      width: 100,
      cellStyle: (params) => {
        if (params.data.dona > 10) {
          return { color: "white", backgroundColor: "green" };
        } else if (params.data.dona <= 10) {
          return { color: "white", backgroundColor: "#f08a90" };
        }
        return null;
      },
    },
    {
      headerName: "Miqdori",
      field: "quantity",
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: CurrencyCellRendererQuantity,
      width: 100,
      cellStyle: (params) => {
        if (params.data.quantity > 10) {
          return { color: "white", backgroundColor: "green" };
        } else if (params.data.quantity <= 10) {
          return { color: "white", backgroundColor: "#f08a90" };
        }
        return null;
      },
    },
    {
      headerName: "Birlik",
      field: "product_unit",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 100,
    },
    {
      headerName: "Mobile",
      field: "status",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 100,
    },
    {
      headerName: "Open market",
      field: "is_open",
      sortable: true,
      filter: "agTextColumnFilter",
    },
  ];

  // End of currency renderer functions

  onBtnExport() {
    this.gridApi.exportDataAsExcel();
  }

  cellStyling(params: any) {
    if (
      Number(params.data.quantity) < 100 &&
      Number(params.data.quantity) >= 50
    ) {
      return { "background-color": "#17A589; color: white;" };
    } else if (
      Number(params.data.quantity) < 50 &&
      Number(params.data.quantity) > 10
    ) {
      return { "background-color": "#e8c855; color: black;" };
    } else if (Number(params.data.quantity) <= 10) {
      return { "background-color": "#f08a90; color: white;" };
    }
  }

  // Excell export

  ngOnInit(): void {
    this.searchProduct = localStorage.getItem("searchp");

    this.isLoading = true;
    const type: number = Number(localStorage.getItem("t_id"));
    const warehouse = localStorage.getItem("w_id");
    if (type > 0) {
      this.onFilterProductType(type);
    } else {
      this.inventoryService.getInventory().subscribe((result) => {
        if (window.history.state.id === 0) {
          if (localStorage.getItem("w_id")) {
            this.rowData = result.filter((x) => {
              return x.warehouse === localStorage.getItem("w_id");
            });
            this.calculateTotalSums(this.rowData);
            this.isLoading = false;
          } else {
            this.rowData = result;
            this.calculateTotalSums(this.rowData);
            this.isLoading = false;
          }
        } else {
          this.rowData = result.filter((x) => {
            return x.branch_id === window.history.state.id;
          });
          if (localStorage.getItem("w_id")) {
            this.rowData = result.filter((x) => {
              return x.warehouse === localStorage.getItem("w_id");
            });
            this.calculateTotalSums(this.rowData);
            this.isLoading = false;
          } else {
            this.calculateTotalSums(this.rowData);
            this.isLoading = false;
          }
        }
      });
    }

    this.warehouse$ = this.inventoryService.getWarehouse();
    if (this.rowData !== undefined) {
      this.isLoading = false;
    }

    // this.types$ = this.inventoryService.getType();

    setInterval((a) => {
      if (this.router.url === "inventory") {
        this.inventoryService.getInventory().subscribe((x) => {
          this.rowData = x;
        });
      }
    }, 900000);

    if (this.searchProduct !== "" || this.searchProduct !== null) {
      this.inventoryService
        .searchInventoryProduct(this.searchProduct)
        .pipe(take(1))
        .subscribe((x: any) => {
          this.rowData = x;
          localStorage.setItem("searchp", this.searchProduct);
        });
    } else {
      localStorage.removeItem("searchp");
    }
  }

  onSearchProduct(): void {
    this.searchProduct;
    if (this.searchProduct !== "" || this.searchProduct !== null) {
      this.inventoryService
        .searchInventoryProduct(this.searchProduct)
        .pipe(take(1))
        .subscribe((x: any) => {
          this.rowData = x;
          localStorage.setItem("searchp", this.searchProduct);
        });
    } else {
      this.inventoryService
        .getInventory()
        .pipe(take(1))
        .subscribe((x: any) => {
          this.rowData = x;
          localStorage.removeItem("searchp");
        });
    }
  }

  calculateTotalSums(rowData1: any) {
    this.totalCostUz = 0;
    this.totalCostUs = 0;
    this.totalSellCostUz = 0;
    this.totalSellCostUs = 0;
    this.totalQuantity = 0;
    for (let i = 0; i < rowData1.length; i++) {
      this.totalCostUz =
        this.totalCostUz +
        Number(rowData1[i].cost_uz) * Number(rowData1[i].quantity);
      this.totalCostUs =
        this.totalCostUs +
        Number(rowData1[i].cost_us) * Number(rowData1[i].quantity);
      this.totalSellCostUz =
        this.totalSellCostUz +
        Number(rowData1[i].sell_cost_uz) * Number(rowData1[i].quantity);
      this.totalSellCostUs =
        this.totalSellCostUs +
        Number(rowData1[i].sell_cost_us) * Number(rowData1[i].quantity);
      // Calculate total quantity
      this.totalQuantity = this.totalQuantity + Number(rowData1[i].quantity);
    }
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  openDialog() {
    this.router.navigate(["../../inventory/invoice"]);
  }

  onRowDoubleClick() {
    this.addProduct();
  }

  addProduct() {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (this.gridApi.getSelectedRows().length > 0) {
      const dialogRef = this.dialog.open(InventoryAddComponent, {
        panelClass: ["animate__animated", "animate__slideInRight"],
        width: "60%",
        height: "100%",
        position: { right: "0" },
        data: selectedRow,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.ngOnInit();
      });
    } else {
      alert("Maxsulot tanlang");
    }
  }

  updateCost(event) {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (this.gridApi.getSelectedRows().length > 0) {
      const dialogRef = this.dialog.open(ProductUpdateComponent, {
        panelClass: ["animate__animated", "animate__slideInRight"],
        width: "30%",
        height: "100%",
        position: { right: "0" },
        data: selectedRow,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.ngOnInit();
      });
    } else {
      alert("Maxsulot tanlang");
    }
  }

  onFilterProduct(id: string, wId: number) {
    this.types$ = this.inventoryService.getTypeByWarehouse(wId);
    if (id !== "0") {
      this.inventoryService.getWarehouseType(id).subscribe((x) => {
        this.rowData = x;
        this.btnWarehouse = id;
        this.calculateTotalSums(this.rowData);
        localStorage.setItem("w_id", id);
        localStorage.removeItem("t_id");
      });
    } else {
      this.inventoryService.getInventory().subscribe((x) => {
        this.types$ = this.inventoryService.getType();
        this.rowData = x;
        localStorage.removeItem("w_id");
        localStorage.removeItem("t_id");
        this.calculateTotalSums(this.rowData);
      });
    }
  }

  addProductModal() {
    const app: any = {
      id: 0,
      product_name: "Maxsulot nomi",
      barcode: null,
      image_url: null,
    };
    const dialogRef = this.dialog.open(InventoryAddComponent, {
      panelClass: ["animate__animated", "animate__slideInRight"],
      width: "50%",
      data: app,
      height: "100%",
      position: { right: "0" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.inventoryService.getInventory()
      //   .subscribe(x => {
      //     // this.products = result1;
      //   });
      this.ngOnInit();
    });
  }

  onHistory(): void {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (this.gridApi.getSelectedRows().length > 0) {
      this.router.navigate(["/inventory/history", selectedRow.id]);
    }
  }

  onFilterProductType(id: number): void {
    if (id !== 0) {
      this.inventoryService.getProductTypeGet(id).subscribe((x) => {
        this.rowData = x;
        // this.btnWarehouse = id;
        this.calculateTotalSums(this.rowData);
        localStorage.setItem("t_id", String(id));
        localStorage.removeItem("w_id");
      });
    } else {
      this.inventoryService.getInventory().subscribe((x) => {
        this.rowData = x;
        localStorage.removeItem("t_id");
        localStorage.removeItem("w_id");
        this.calculateTotalSums(this.rowData);
      });
    }
  }

  onArchiveProduct() {
    const selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow.length > 0) {
      if (confirm("Rostdan maxsulotni o'chirmoqchimisiz ?")) {
        selectedRow.forEach((i) => {
          this.inventoryService.addArchiveProduct(i.id).subscribe((r) => {
            if (r.message === "1") {
              // this.ngOnInit();
            }
          });
        });
      } else {
        alert("Jadvaldan maxsulot tanlang!");
      }
      this.ngOnInit();
    }
  }

  onOpenArchiveProduct(): void {
    const dialogRef = this.dialog.open(ArchiveComponent, {
      panelClass: ["animate__animated", "animate__slideInRight"],
      width: "50%",
      height: "100%",
      position: { right: "0" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.inventoryService.getInventory()
      //   .subscribe(x => {
      //     // this.products = result1;
      //   });
      this.ngOnInit();
    });
  }

  openNewTab(): void {
    window.open("http://esbd.uz/auth/login", "_blank");
  }
}
