import { Component, Input, OnInit } from "@angular/core";
import { InstallmentService } from "../../installment.service";

@Component({
  selector: "app-contract-form",
  templateUrl: "./contract-form.component.html",
  styleUrls: ["./contract-form.component.css"],
})
export class ContractFormComponent implements OnInit {
  @Input() patent;
  @Input() ownerName;

  @Input() clientName;
  @Input() products;
  @Input() totalCost;
  @Input() withAdditional;
  @Input() graph;
  @Input() initialPayment;
  @Input() currentDate;
  @Input() percent;
  @Input() duration;
  @Input() division;
  @Input() productCount;
  @Input() today;
  @Input() saleId;
  totalProductQuantity = 0;

  constructor(private insService: InstallmentService) {}

  ngOnInit(): void {
    console.log(this.clientName);
    this.totalProductQuantity = 0;
    this.products.forEach((product) => {
      this.totalProductQuantity += product.quantity;
    });
  }

  onPrint() {
    const printContent = document.getElementById("forPrint");
    let htmlPrint =
      "" +
      "<style>" +
      "body {" +
      "font-size: 13px;" +
      "}" +
      "@media print {" +
      ".pagebreak {" +
      "page-break-before: always;" +
      "}" +
      "button {" +
      "display: none;" +
      "}" +
      "table, th, td {" +
      "font-size: 11px;" +
      "}" +
      ".d-flex {" +
      "display: flex;" +
      "justify-content: space-between;" +
      "}" +
      ".d-flex-center {" +
      "display: flex;" +
      "justify-content: center;" +
      "margin: 0;" +
      "padding: 0 0 0 0 !important;" +
      "}" +
      "</style>";
    htmlPrint += printContent.outerHTML;
    const WindowPrt = window.open();
    WindowPrt.document.write(`
    <html lang="html">
            <head>
            <!-- CSS only -->
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/ng-zorro-antd@13.4.0/table/style/index.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
            <title></title>
            </head>
            <style>
            body {
              width: 95%;
            }
            p {
            margin: 0;
            };
            h5 {
            margin: 0;
            };
            </style>
        <body onload="window.print();window.close()">${htmlPrint}</body>
          </html>`);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}
