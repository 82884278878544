import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomerAddComponent } from "../../customer-add/customer-add.component";
import { PosService } from "../../pos.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-debt-note",
  templateUrl: "./debt-note.component.html",
  styleUrls: ["./debt-note.component.css"],
})
export class DebtNoteComponent implements OnInit {
  debtForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<DebtNoteComponent>,
    private posService: PosService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.debtForm = this.fb.group({
      id: [0, [Validators.required]],
      debtDate: [new Date(), [Validators.required]],
      debtSum: [0, [Validators.required]],
      comment: ["Qarzga oldi", [Validators.required]],
    });
    if (this.data) {
      this.debtForm.patchValue({
        id: this.data.id,
        debtDate: this.data.debt_date,
        debtSum: this.data.debt_amount,
        comment: this.data.comment,
      });
    }
  }

  onSubmit() {
    this.posService
      .updateDebt(
        this.debtForm.value.id,
        this.debtForm.value.debtDate,
        this.debtForm.value.debtSum,
        this.debtForm.value.comment
      )
      .subscribe((res) => {
        if (res.message === "1") {
          this.dialogRef.close();
        }
      });
  }
}
