import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { InventoryService } from "../../crm/inventory/inventory.service";
import { ProdService } from "../prod.service";

interface Product {
  id: number;
  barcode: string;
  product_name: string;
}

export interface Line {
  id: number;
  name: string;
}

@Component({
  selector: "app-m-production",
  templateUrl: "./m-production.component.html",
  styleUrls: ["./m-production.component.css"],
})
export class MProductionComponent implements OnInit, OnDestroy {
  mp: FormGroup;
  myControl = new FormControl();
  filteredOptions: Observable<Product[]>;
  options: Product[] = [];
  productControl = new FormControl();
  filterProducts: Observable<Product[]>;
  products: Product[] = [];
  public raws: FormArray;
  private manualProductionSubs = new Subscription();
  isActive = true;
  today: Date;
  daysBefore: Date;
  lines = [];

  constructor(
    private fb: FormBuilder,
    private inventoryService: InventoryService,
    private prodService: ProdService
  ) {}

  ngOnInit(): void {
    const a = new Date();
    this.daysBefore = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    this.today = a;
    this.mp = this.fb.group({
      line: ["", [Validators.required]],
      id: [null, Validators.required],
      barcode: [null, Validators.required],
      quantity: [null, [Validators.required, Validators.maxLength(10)]],
      date: [a, Validators.required],
      raws: this.fb.array([this.createAddress()]),
    });
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filter(value))
    );
    this.filterProducts = this.productControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterProduct(value))
    );
    this.inventoryService.getBranchProducts().subscribe((result: Product[]) => {
      this.options = result;
      this.products = result;
    });
    this.manualProductionSubs = this.prodService.cast$.subscribe((result) => {
      this.ngOnInit();
    });

    this.prodService.getLine().subscribe((result: Line[]) => {
      this.lines = result;
    });
  }

  filter(val: any): Product[] {
    return this.options.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === "object") {
        val = "";
      } else {
        const TempString = item.product_name + item.barcode;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.product_name;
  }

  // Setting barcode and id
  setBarcode(id: any) {
    this.mp.controls.barcode.setValue(id.barcode);
    this.mp.controls.id.setValue(id.id);
  }

  filterProduct(val: any): Product[] {
    return this.products.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === "object") {
        val = "";
      } else {
        const TempString = item.product_name + item.barcode;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  displayFn1(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.product_name;
  }

  createAddress(): FormGroup {
    return this.fb.group({
      r_id: [null, Validators.required],
      name: [null, Validators.required],
      barcode: [null, Validators.required],
      quantity: [null, [Validators.required, Validators.maxLength(10)]],
    });
  }

  get addressControls() {
    return this.mp.get("raws")["controls"];
  }

  addRow() {
    if (this.mp.valid) {
      this.raws = this.mp.get("raws") as FormArray;
      this.raws.push(this.createAddress());
    } else {
      alert("Barcha malumotlarni kiriting!");
    }
  }

  removeAddress(i: number) {
    this.raws.removeAt(i);
  }

  setBarcodeRows(value: any, id: number) {
    this.mp
      .get("raws")
      ["controls"][id].controls.barcode.setValue(value.barcode);
    this.mp.get("raws")["controls"][id].controls.r_id.setValue(value.id);
    this.mp
      .get("raws")
      ["controls"][id].controls.name.setValue(value.product_name);
  }

  onSubmit() {
    this.isActive = false;
    if (confirm("Rostdan ham ishlab chiqarasizmi?")) {
      if (this.mp.valid) {
        this.prodService
          .postManualProduction(
            this.mp.value.line,
            this.mp.value.id,
            this.mp.value.barcode,
            this.mp.value.quantity,
            this.mp.value.raws,
            this.mp.value.date
          )
          .subscribe((result) => {
            if (result.message === "1") {
              this.isActive = true;
              this.mp.get("raws")["controls"].length = 1;
              this.mp.controls.id.setValue("");
              this.mp.controls.barcode.setValue("");
              this.mp.controls.quantity.setValue("");
            }
          });
      } else {
        alert("Hamma malumotlarni kiriting");
      }
    }
  }

  ngOnDestroy(): void {
    this.manualProductionSubs.unsubscribe();
  }
}
