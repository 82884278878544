import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AllModules, Module } from "@ag-grid-enterprise/all-modules";
import { Observable } from "rxjs";
import { InventoryService } from "../../../crm/inventory/inventory.service";
import { ProdService } from "../../prod.service";
import { Router } from "@angular/router";
import { map, startWith } from "rxjs/operators";

interface Product {
  id: number;
  barcode: string;
  product_name: string;
}

interface Layout {
  l_id: number;
  barcode: string;
  name: string;
  quantity: number;
  line: number;
  sum_cost_uz: number;
  sum_cost_us: number;
}

interface Line {
  id: number;
  name: string;
}

@Component({
  selector: "app-constructor",
  templateUrl: "./constructor.component.html",
  styleUrls: ["./constructor.component.css"],
})
export class ConstructorComponent implements OnInit {
  layoutForm: FormGroup;
  rowData: any;
  gridApi;
  gridColumnApi;
  defaultColDef;
  public modules: Module[] = AllModules;
  myControl = new FormControl();
  filteredOptions: Observable<Product[]>;
  options: Product[] = [];
  layouts: Layout[] = [];
  lines: Line[] = [];
  totolCostUz: number;
  totolCostUs: number;

  constructor(
    private inventoryService: InventoryService,
    private prodService: ProdService,
    private router: Router
  ) {}

  columnDefs1 = [
    { headerName: "Id", field: "id", sortable: true, width: 70 },
    {
      headerName: "Barcode",
      field: "barcode",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 150,
    },
    {
      headerName: "Product",
      width: 300,
      field: "product_name",
      sortable: true,
      filter: "agTextColumnFilter",
    },
  ];

  ngOnInit(): void {
    this.layoutForm = new FormGroup({
      line: new FormControl(null, Validators.required),
      barcodeLayout: new FormControl(null, Validators.required),
      nameLayout: new FormControl(null, Validators.required),
      idLayout: new FormControl(null, Validators.required),
      id: new FormControl(null, Validators.required),
      quantity: new FormControl(1, Validators.required),
      production_quantity: new FormControl(1, Validators.required),
      sum_cost_uz: new FormControl(0),
      sum_cost_us: new FormControl(0),
    });
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filter(value))
    );

    this.inventoryService.getInventory().subscribe((result: Product[]) => {
      this.options = result;
    });

    this.prodService.getLine().subscribe((result: Line[]) => {
      this.lines = result;
    });
    this.rowData = this.inventoryService.getInventory();
  }

  onFirstDataRendered(param) {
    this.gridApi = param.api;
    this.gridColumnApi = param.columnApi;
    param.api.sizeColumnsToFit();
  }

  filter(val: any): Product[] {
    return this.options.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === "object") {
        val = "";
      } else {
        const TempString = item.product_name + item.barcode;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  onRowDoubleClick() {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    this.layoutForm.controls.nameLayout.setValue(selectedRow.product_name);
    this.layoutForm.controls.id.setValue(selectedRow.id);
    this.prodService
      .getProductLayout(selectedRow.id)
      .subscribe((result: Layout[]) => {
        this.layouts = result;
        if (this.layouts.length > 0) {
          this.totolCostUz = this.layouts[0]["sum_cost_uz"];
          this.totolCostUs = this.layouts[0]["sum_cost_us"];
          this.layoutForm.controls.sum_cost_uz.setValue(
            this.layouts[0]["sum_cost_uz"]
          );
          this.layoutForm.controls.sum_cost_uz.setValue(
            this.layouts[0]["sum_cost_us"]
          );
          this.layoutForm.controls.line.patchValue(this.layouts[0].line);
          this.layoutForm.controls.production_quantity.setValue(
            this.layouts[0]["production_quantity"]
          );
        }
      });
  }

  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.product_name;
  }

  // Setting barcode and id
  setBarcode(id: any) {
    this.layoutForm.controls.barcodeLayout.setValue(id.barcode);
    this.layoutForm.controls.idLayout.setValue(id.id);
  }

  onSubmit() {
    this.prodService
      .postLayout(
        this.layoutForm.value.id,
        this.layoutForm.value.idLayout,
        this.layoutForm.value.quantity,
        this.layoutForm.value.line,
        this.layoutForm.value.production_quantity
      )
      .subscribe((result) => {
        this.prodService
          .getProductLayout(this.layoutForm.value.id)
          .subscribe((result1: Layout[]) => {
            this.layouts = result1;
            this.totolCostUz = this.layouts[0]["sum_cost_uz"];
            this.totolCostUs = this.layouts[0]["sum_cost_us"];
            this.layoutForm.controls.sum_cost_uz.setValue(
              this.layouts[0]["sum_cost_uz"]
            );
            this.layoutForm.controls.sum_cost_uz.setValue(
              this.layouts[0]["sum_cost_us"]
            );
            this.layoutForm.controls.idLayout.reset();
            this.layoutForm.controls.barcodeLayout.reset();
            this.myControl.setValue({ name: "" });
            this.layoutForm.controls.quantity.reset();
          });
      });
  }

  onRemove(id: number) {
    if (confirm("Rostdan ham o'chirishni xoxlaysizmi?")) {
      this.prodService.deleteLayoutItem(id).subscribe((result) => {
        if (result.message === "1") {
          this.prodService
            .getProductLayout(this.layoutForm.value.id)
            .subscribe((result1: Layout[]) => {
              this.layouts = result1;
              if (this.layouts.length > 0) {
                this.totolCostUz = this.layouts[0]["sum_cost_uz"];
                this.totolCostUs = this.layouts[0]["sum_cost_us"];
                this.layoutForm.controls.sum_cost_uz.setValue(
                  this.layouts[0]["sum_cost_uz"]
                );
                this.layoutForm.controls.sum_cost_uz.setValue(
                  this.layouts[0]["sum_cost_us"]
                );
              } else {
                this.totolCostUz = 0;
                this.totolCostUs = 0;
                this.layoutForm.controls.sum_cost_uz.setValue(0);
                this.layoutForm.controls.sum_cost_uz.setValue(0);
              }
            });
        }
      });
    }
  }
}
