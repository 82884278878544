import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit {
  branchInfo: any;
  isLoading = false;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.getBranch();
  }

  onOpenTelegram(bool: boolean) {
    if (bool) {
      window.open("https://t.me/Skywalker_Luke", "_blank");
    } else {
      window.open("https://my.click.uz/services", "_blank");
    }
  }

  getBranch() {
    this.isLoading = true;
    this.authService
      .getBranchInfo()
      .pipe(take(1))
      .subscribe((branch) => {
        this.branchInfo = branch.data;
        this.isLoading = false;
      });
  }
}
