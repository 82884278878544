import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { dateParser } from "../dateParser";

const BACKEND_URL = environment.api_URL;

@Injectable({ providedIn: "root" })
export class InventoryService {
  constructor(private router: Router, private http: HttpClient) {}

  getInventory(): any {
    return this.http.get(BACKEND_URL + "crm/inventory/get");
  }

  searchInventoryProduct(name: string): any {
    const data = {
      search: name,
    };
    return this.http.post<any>(
      BACKEND_URL + "inventory/inventory/get/search",
      data
    );
  }

  getBranchProducts(): any {
    return this.http.get(BACKEND_URL + "crm/inventory/branch/products/get");
  }

  getArchiveProducts(): any {
    return this.http.get(BACKEND_URL + "crm/inventory/archive/get");
  }

  getWarehouseType(id: string): any {
    return this.http.get(BACKEND_URL + "crm/inventory/warehouse/type/get" + id);
  }

  getProductTypeGet(id: number): any {
    const data = {
      type: id,
    };
    return this.http.post(BACKEND_URL + "crm/inventory/product/type/get", data);
  }

  getProduct(id: number): any {
    return this.http.get(BACKEND_URL + "crm/inventory/product/get/" + id);
  }
  getProductByBarcode(barcode: number): any {
    return this.http.get(
      BACKEND_URL + "crm/inventory/product/barcode/get/" + barcode
    );
  }

  getInvoice() {
    return this.http.get(BACKEND_URL + "crm/inventory/invoice/get");
  }

  getInvoiceFilter(data: any) {
    const from = dateParser(data.from);
    const to = dateParser(data.to);
    const app = {
      from,
      to,
    };
    localStorage.setItem("from_i_p", from);
    localStorage.setItem("to_i_p", to);
    return this.http.post(
      BACKEND_URL + "crm/inventory/invoice/get/filter",
      app
    );
  }
  getInvoiceReport(data: any): any {
    const from = dateParser(data.from);
    const to = dateParser(data.to);
    const app = {
      from,
      to,
    };
    return this.http.post(
      BACKEND_URL + "crm/inventory/invoice/report/get",
      app
    );
  }

  getCompany(): any {
    return this.http.get(BACKEND_URL + "crm/inventory/company/get");
  }

  getCompanyBalanceWithFilters(type: number, name: string): any {
    const payload = {
      name: name,
      type: Number(type),
    };
    return this.http.post(
      BACKEND_URL + "crm/cashier/balance/get/filters",
      payload
    );
  }

  getCurrency() {
    return this.http.get<any>(BACKEND_URL + "crm/inventory/currency/get");
  }

  getType(): any {
    return this.http.get(BACKEND_URL + "crm/inventory/type/get/all");
  }

  getBranchType(): any {
    return this.http.get(BACKEND_URL + "crm/inventory/type/branch/get");
  }
  getTypeByWarehouse(id: number): any {
    const data = {
      id,
    };
    return this.http.post<any>(BACKEND_URL + "crm/inventory/type/get/w", data);
  }

  getUnit() {
    return this.http.get(BACKEND_URL + "crm/inventory/unit/get");
  }
  getBalance(id: number): any {
    const company: any = {
      id,
    };
    return this.http.post<any>(
      BACKEND_URL + "crm/inventory/balance/get",
      company
    );
  }

  getWarehouse() {
    return this.http.get<any>(BACKEND_URL + "crm/inventory/warehouse/get");
  }

  postInvoice(
    branch: number,
    items: any,
    company: number,
    crOn: Date,
    opType: number,
    currency: number,
    cost: number,
    name: number,
    comment: string
  ) {
    const invoice1: any = {
      branch,
      items,
      company,
      crOn,
      opType,
      currency,
      cost,
      name,
      comment,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/inventory/invoice/add",
      invoice1
    );
  }

  postCompany(
    branch: number,
    name: string,
    email: string,
    comment: string,
    isOpen: boolean,
    id: number,
    type: number,
    phone: string,
    location: string
  ): any {
    let open = 0;
    if (isOpen === true) {
      open = 1;
    } else {
      open = 0;
    }
    const company: any = {
      branch,
      name,
      email,
      comment,
      open,
      id,
      type,
      phone,
      location,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/inventory/company/add",
      company
    );
  }

  postCurrency(name: string): any {
    const currency: any = {
      name,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/inventory/currency/add",
      currency
    );
  }

  postType(
    branch: number,
    warehouse: number,
    name: string,
    unit: number,
    id: number,
    sell: boolean
  ): any {
    let isSell = 1;
    if (sell === true) {
      isSell = 1;
    } else {
      isSell = 0;
    }
    const type: any = {
      branch,
      warehouse,
      name,
      unit,
      id,
      sell: isSell,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/inventory/type/add",
      type
    );
  }

  postWarehouse(
    branch: number,
    name: string,
    comment: number,
    id: number
  ): any {
    const type: any = {
      branch,
      name,
      comment,
      id,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/inventory/warehouse/add",
      type
    );
  }

  postProduct(
    branch: number,
    id: number,
    warehouse: number,
    barcode: string,
    type: number,
    name: string,
    isOpen: boolean,
    image: FileList,
    isMobile: boolean,
    layoutQuantity: number,
    unit: number
  ) {
    const product = new FormData();
    if (isOpen === true) {
      product.append("isOpen", "1");
    } else {
      product.append("isOpen", "0");
    }
    if (isMobile === true) {
      product.append("isMobile", "A");
    } else {
      product.append("isMobile", "P");
    }

    if (image === undefined) {
      const app = {
        branch,
        id,
        warehouse,
        barcode,
        type,
        name,
        isOpen,
        isMobile,
        layout_quantity: layoutQuantity,
        unit,
      };
      return this.http.post<{ message: string }>(
        BACKEND_URL + "crm/inventory/add/1",
        app
      );
    } else {
      product.append("branch", branch.toString());
      product.append("id", id.toString());
      product.append("warehouse", warehouse.toString());
      product.append("barcode", barcode);
      product.append("type", type.toString());
      product.append("name", name);
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < image.length; i++) {
        product.append("image", image[i], name);
      }
      product.append("layout_quantity", layoutQuantity.toString());
      product.append("unit", unit.toString());
      return this.http.post<{ message: string }>(
        BACKEND_URL + "crm/inventory/add",
        product
      );
    }
  }

  updateProductCost(
    type: number,
    id: number,
    base: number,
    cost: number,
    operType: number,
    warehouse: number
  ) {
    const app = {
      type,
      id,
      base,
      cost,
      operType,
      warehouse,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/inventory/product/update",
      app
    );
  }

  posReturnSale(id: number) {
    const app = {
      id,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/inventory/return",
      app
    );
  }

  // get historical data of inventory
  getInventoryHistory(id: number, date1: string, date2: string): any {
    const app = {
      id,
      date1,
      date2,
    };
    return this.http.post<any>(
      BACKEND_URL + "crm/inventory/history/report",
      app
    );
  }

  getInventoryHistoryType(id: number, date1: string, date2: string): any {
    const app = {
      type_id: id,
      date1,
      date2,
    };
    return this.http.post<any>(BACKEND_URL + "crm/inventory/history/type", app);
  }

  getTypeHistoryProducts(id: number, date1: string, date2: string): any {
    const app = {
      id,
      date1,
      date2,
    };
    return this.http.post<any>(
      BACKEND_URL + "crm/inventory/history/type/products",
      app
    );
  }

  // get invoice products history
  getInvoiceProducts(): any {
    return this.http.get<any>(BACKEND_URL + "crm/inventory/invoice/products");
  }

  getInvoiceProductsFilter(date1: string, date2: string): any {
    const app = {
      date1,
      date2,
    };
    return this.http.post<any>(
      BACKEND_URL + "crm/inventory/invoice/products/filter",
      app
    );
  }

  postExcelImport(json: any): any {
    const app = {
      data: json,
    };
    return this.http.post<any>(
      BACKEND_URL + "crm/inventory/invoice/products/excel",
      app
    );
  }

  getOwnerUsers(): any {
    return this.http.get<any>(BACKEND_URL + "user/owner/list");
  }

  getProductImages(id: number): any {
    return this.http.get<any>(
      BACKEND_URL + "crm/inventory/product/images/" + id
    );
  }

  deleteProductImages(id: number): any {
    return this.http.get<any>(
      BACKEND_URL + "crm/inventory/product/images/delete/" + id
    );
  }

  addArchiveProduct(id: number): any {
    const data = {
      id,
    };
    return this.http.post(
      BACKEND_URL + "crm/inventory/product/arxiv/add",
      data
    );
  }

  removeArchiveProduct(id: number): any {
    const data = {
      id,
    };
    return this.http.post(
      BACKEND_URL + "crm/inventory/product/arxiv/remove",
      data
    );
  }

  getInvoiceProductsName(id: number, type: number): any {
    const data = {
      id,
      type,
    };
    return this.http.post(
      BACKEND_URL + "crm/inventory/invoice/products/name",
      data
    );
  }
}
