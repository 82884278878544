import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { InventoryService } from "../../inventory.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { InventoryAddComponent } from "../../inventory-add/inventory-add.component";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../../../system/client-create/user.service";
import { CompanyAddComponent } from "../company-add/company-add.component";

interface Company {
  id: number;
  name: string;
  type: number;
  cost: number;
}

interface Balance {
  owner: number;
  branch: number;
  company: number;
  type: number;
  cost: number;
}

interface OpType {
  id: number;
  name: string;
}

interface Currency {
  id: number;
  name: string;
}

interface Product {
  id: number;
  product_name: string;
  barcode: string;
  type: string;
  quantity: number;
  sell_cost_uz: number;
  sell_cost_us: number;
  branch_id: number;
}

@Component({
  templateUrl: "./invoice-add.component.html",
  styleUrls: ["./invoice-add.component.css"],
})
export class InvoiceAddComponent implements OnInit {
  company: Company[] = [];
  currency$: Observable<Currency[]>;
  operType: OpType[] = [
    { id: 1, name: "Kirim" },
    { id: 2, name: "Chiqim" },
  ];
  invoiceForm: FormGroup;
  myControl = new FormControl();
  productControl = new FormControl();
  filteredOptions: Observable<Company[]>;
  filterProducts: Observable<Product[]>;
  options: Company[] = [];
  products: Product[] = [];
  sum = 0;
  dollar = 0;
  hr = 0;
  hrDollar = 0;
  labelCost: number;
  labelBtCost: number;
  labelQuantity: number;
  public items: FormArray;
  isDisable = false;
  branches = [];
  oldInvoice;

  constructor(
    public dialog: MatDialog,
    private inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.invoiceForm = this.formBuilder.group({
      branch: ["", Validators.required],
      company: ["", Validators.required],
      crOn: ["", Validators.required],
      opType: ["", Validators.required],
      currency: ["", Validators.required],
      cost: ["", [Validators.required, Validators.maxLength(15)]],
      name: ["", [Validators.required, Validators.maxLength(20)]],
      comment: ["", [Validators.required, Validators.maxLength(500)]],
      items: this.formBuilder.array([this.createAddress()]),
    });

    // Getting inventory
    this.inventoryService.getInventory().subscribe((result: Product[]) => {
      this.products = result;
      this.setProducts();
    });

    this.oldInvoice = window.history.state;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filter(value))
    );

    this.filterProducts = this.productControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterProduct(value))
    );
    // Getting currencies
    this.currency$ = this.inventoryService.getCurrency();
    // Getting companies
    this.inventoryService.getCompany().subscribe((result: Company[]) => {
      this.company = result;
    });
    //  Getting companies
    this.inventoryService.getCompany().subscribe((result: Company[]) => {
      this.options = result;
    });

    // getting list of branches
    this.userService.getBranchesByOwner().subscribe((x) => {
      this.branches = x;
    });
  }

  setProducts(): void {
    if (window.history.state.branch !== undefined) {
      this.invoiceForm.controls.branch.setValue(this.oldInvoice.branch);
      this.invoiceForm.controls.cost.setValue(this.oldInvoice.cost);
      this.invoiceForm.controls.name.setValue(this.oldInvoice.invoice_name);
      this.invoiceForm.controls.comment.setValue(this.oldInvoice.comment);
      this.invoiceForm.controls.currency.patchValue(
        this.oldInvoice.currency_id
      );
      this.invoiceForm.controls.opType.patchValue(
        this.oldInvoice.operation_type_id
      );

      this.oldInvoice.items.forEach((a, index: number) => {
        this.invoiceForm
          .get("items")
          ["controls"][index].controls.item_id.setValue(a.item_id);
        this.invoiceForm
          .get("items")
          ["controls"][index].controls.barcode.setValue(a.barcode);
        this.invoiceForm
          .get("items")
          ["controls"][index].controls.quantity.setValue(a.quantity);
        this.invoiceForm
          .get("items")
        ["controls"][index].controls.cost.setValue(a.cost);
        this.invoiceForm
          .get("items")
          ["controls"][index].controls.sum.setValue(a.sum);
        this.invoiceForm
          .get("items")
          ["controls"][index].controls.bt.setValue(a.bt);
        this.invoiceForm
          .get("items")
          ["controls"][index].controls.sum_bt.setValue(a.sum_bt);
        this.products.forEach((product) => {
          if (product.id === a.item_id) {
            this.invoiceForm
              .get("items")
              ["controls"][index].controls.type.setValue(product.type);
          }
        });
        this.items = this.invoiceForm.get("items") as FormArray;
        this.items.push(this.createAddress());
      });
      this.items.removeAt(this.items.length - 1);
    }
  }

  createAddress(): FormGroup {
    return this.formBuilder.group({
      item_id: ["", Validators.required],
      barcode: ["", Validators.required],
      type: ["", Validators.required],
      quantity: ["", [Validators.required, Validators.maxLength(9)]],
      cost: [0, [Validators.required, Validators.maxLength(20)]],
      sum: [0, Validators.required],
      sale_cost: [0, Validators.required],
      bt: ["0", [Validators.required, Validators.maxLength(2)]],
      sum_bt: ["", Validators.required],
    });
  }

  get addressControls() {
    return this.invoiceForm.get("items")["controls"];
  }

  addAddress(i: number): void {
    if (this.invoiceForm.valid) {
      this.items = this.invoiceForm.get("items") as FormArray;
      this.items.push(this.createAddress());
      this.onKey(i);
    } else {
      console.log(this.invoiceForm.value);
      alert("Hamma malumotlarni kiriting");
    }
  }

  removeAddress(i: number) {
    this.items.removeAt(i);
    this.onKey(i);
  }

  clearItems() {
    this.invoiceForm.get("items")["controls"].length = 1;
    this.sum = 0;
    this.hr = 0;
    this.dollar = 0;
    this.invoiceForm.controls.cost.setValue(0);
    this.invoiceForm.controls.name.setValue("");
    this.invoiceForm.controls.comment.setValue("");
    this.labelCost = 0;
    this.labelQuantity = 0;
    this.labelBtCost = 0;
  }

  // ----------------- Searching items ---------------
  // Filter companies
  filter(val: any): Company[] {
    return this.options.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === "object") {
        val = "";
      } else {
        const TempString = item.name;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
  }

  // Filter products
  filterProduct(val: any): Product[] {
    let apple = this.products.filter((item: any) => {
      // If the user selects an option, the value becomes a Human object,
      // therefore we need to reset the val for the filter because an
      // object cannot be used in this toLowerCase filter
      if (typeof val === "object") {
        val = "";
      } else {
        const TempString = item.product_name + item.barcode;
        return TempString.toLowerCase().includes(val.toLowerCase());
      }
    });
    if (apple.length < 1) {
      apple = [
        {
          id: 0,
          barcode: "0",
          product_name: "Add new",
          type: "0",
          quantity: 0,
          sell_cost_uz: 0,
          sell_cost_us: 0,
          branch_id: 0,
        },
      ];
    }
    return apple;
  }

  displayFn(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.product_name;
  }

  displayFnCompany(item: any): any {
    // tslint:disable-next-line:triple-equals
    if (item == undefined) {
      return;
    }
    return item.name;
  }

  // Setting barcode and id
  setBarcode(id: any, id1: number) {
    const app: any = {
      id: 0,
      product_name: this.productControl.value,
      barcode: null,
      image_url: null,
    };
    if (id.id === 0) {
      const dialogRef = this.dialog.open(InventoryAddComponent, {
        panelClass: ["animate__animated", "animate__slideInRight"],
        data: app,
        width: "50%",
        height: "100%",
        position: { right: "0" },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.inventoryService.getInventory().subscribe((result1: Product[]) => {
          this.products = result1;
        });
      });
    } else {
      this.invoiceForm
        .get("items")
        ["controls"][id1].controls.barcode.setValue(id.barcode);
      this.invoiceForm
        .get("items")
        ["controls"][id1].controls.type.setValue(id.type);
      this.invoiceForm
        .get("items")
        ["controls"][id1].controls.item_id.setValue(id.id);
      if (this.invoiceForm.value.currency === 2) {
        this.invoiceForm
          .get("items")
          ["controls"][id1].controls.cost.setValue(
            Number(id.cost_us)
        );

        this.invoiceForm
          .get("items")
          ["controls"][id1].controls.sale_cost.setValue(
            Number(id.sell_cost_us)
          );
      } else if (this.invoiceForm.value.currency === 1) {
        this.invoiceForm
          .get("items")
          ["controls"][id1].controls.cost.setValue(
            Number(id.cost_uz)
        );
        this.invoiceForm
          .get("items")
          ["controls"][id1].controls.sale_cost.setValue(
            Number(id.sell_cost_uz)
          );
      } else if (this.invoiceForm.value.currency === 3) {
        this.invoiceForm
          .get("items")
          ["controls"][id1].controls.cost.setValue(
            Number(id.cost_uz)
        );
        this.invoiceForm
          .get("items")
          ["controls"][id1].controls.sale_cost.setValue(
            Number(id.sell_cost_uz)
          );
      } else if (this.invoiceForm.value.currency === 4) {
        this.invoiceForm
          .get("items")
          ["controls"][id1].controls.cost.setValue(
            Number(id.cost_us)
        );
        this.invoiceForm
          .get("items")
          ["controls"][id1].controls.sale_cost.setValue(
            Number(id.sell_cost_us)
          );
      }
    }
  }

  setCompany(event: any) {
    this.invoiceForm.controls.company.setValue(event.id);
    this.inventoryService
      .getBalance(event.id)
      .subscribe((result: Balance[]) => {
        this.sum = result[0].cost;
        this.dollar = result[1].cost;
        this.hr = result[2].cost;
        this.hrDollar = result[3].cost;
      });
  }

  onKey(id: number) {
    let cost = 0;
    let rowSum = 0;
    let rowQuantity = 0;
    let rowBt = 0;
    this.labelQuantity = parseFloat(
      this.invoiceForm.value.items.reduce((a, { quantity }) => a + quantity, 0)
    );
    this.labelCost = parseFloat(
      this.invoiceForm.value.items.reduce((a, { sum }) => a + sum, 0)
    );
    this.labelBtCost = parseFloat(
      this.invoiceForm.value.items.reduce((a, { sum_bt }) => a + sum_bt, 0)
    );

    cost = this.invoiceForm.get("items")["controls"][id].controls.cost.value;
    rowSum = this.invoiceForm.get("items")["controls"][id].controls.sum.value;
    rowQuantity =
      this.invoiceForm.get("items")["controls"][id].controls.quantity.value;
    rowBt = this.invoiceForm.get("items")["controls"][id].controls.bt.value;

    this.invoiceForm
      .get("items")
      ["controls"][id].controls.sum.setValue(cost * rowQuantity);
    this.invoiceForm
      .get("items")
      ["controls"][id].controls.sum_bt.setValue(
        rowSum + (rowBt / 100) * rowSum
      );
  }

  onSubmit() {
    if (this.invoiceForm.valid) {
      if (confirm("Rostdan ham saqlamoqchimisiz?")) {
        this.isDisable = true;
        this.inventoryService
          .postInvoice(
            this.invoiceForm.value.branch,
            this.invoiceForm.value.items,
            this.invoiceForm.value.company,
            this.invoiceForm.value.crOn,
            this.invoiceForm.value.opType,
            this.invoiceForm.value.currency,
            this.invoiceForm.value.cost,
            this.invoiceForm.value.name,
            this.invoiceForm.value.comment
          )
          .subscribe((result) => {
            if (result.message === this.invoiceForm.value.company) {
              this.clearItems();
              this.inventoryService
                .getBalance(Number(result.message))
                .subscribe((result1: Balance[]) => {
                  // this.balance = result;
                  this.sum = result1[0].cost;
                  this.dollar = result1[1].cost;
                  this.hr = result1[2].cost;
                });
              // this.invoiceForm.reset();
              this.isDisable = false;
            } else {
              this.isDisable = false;
            }
          });
        this.isDisable = false;
      }
    } else {
      this.toast.info("Xamma ma'lumotlarni kiriting!");
    }
  }

  onSelected(value: any): void {
    this.inventoryService.getInventory().subscribe((result: Product[]) => {
      this.products = result;
      this.products = this.products.filter((x) => {
        return x.branch_id === value;
      });
    });
  }

  onAdd(): void {
    const dialogRef = this.dialog.open(CompanyAddComponent, {
      panelClass: ["animate__animated", "animate__slideInRight"],
      width: "50%",
      height: "100%",
      position: { right: "0" },
      data: { id: 0 },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.inventoryService.getCompany().subscribe((result1: Company[]) => {
        this.company = result1;

        this.ngOnInit();
      });
    });
  }
}
