import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BackupFileCleaner } from "@angular/compiler-cli/ngcc/src/writing/cleaning/cleaning_strategies";

const BACKEND_URL = environment.api_URL;

@Injectable({ providedIn: "root" })
export class AccountingService {
  constructor(private http: HttpClient) {}
  getCloseDayTransactions() {
    return this.http.get<any>(BACKEND_URL + "crm/accounting/day/transactions");
  }

  getExchangeCurrency() {
    return this.http.get<any>(BACKEND_URL + "crm/accounting/exchange/get");
  }

  getAdminBalance() {
    return this.http.get<any>(BACKEND_URL + "crm/accounting/admin/balance");
  }

  getDailySaleChart() {
    return this.http.get<any>(BACKEND_URL + "crm/accounting/daily/sale/chart");
  }

  getAccountingOperation() {
    return this.http.get<any>(BACKEND_URL + "crm/accounting/operation");
  }

  postExchangeCurrency(
    type: number,
    cost: number,
    rate: number,
    result: number
  ) {
    const app = {
      type,
      cost,
      rate,
      result,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/accounting/exchange/post",
      app
    );
  }

  postAccountingOperation(
    type: number,
    customer: number,
    currency: number,
    cost: number,
    comment: number,
    isSms: boolean
  ) {
    const sms = isSms ? 1 : 0;
    const app = {
      type,
      customer,
      currency,
      cost,
      comment,
      sms
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/accounting/operation/post",
      app
    );
  }
  postReturnOperation(id: number) {
    const app = {
      id,
    };
    return this.http.post<{ message: string }>(
      BACKEND_URL + "crm/accounting/return/operation",
      app
    );
  }

  getBalanceTotal() {
    return this.http.get<any>(BACKEND_URL + "crm/inventory/balance/total");
  }

  getInventoryTotal(): any {
    return this.http.get<any>(BACKEND_URL + "crm/accounting/inventory/total");
  }

  getAccountingPermissions() {
    return this.http.get<any>(BACKEND_URL + "permission/get/permissions");
  }

  getExchangeTypes(): any {
    return this.http.get<any>(BACKEND_URL + "crm/accounting/exchange/types");
  }
}
